<template>
    <div :style="$route.matched[0].name != 'Inbox' ? '' : 'width: 49.5%'">
        <DatePicker v-model="date" locale="en" :first-day-of-week="startWeek" @input="onDateRangeChange">
            <template #default="{ togglePopover }">
                <p class="chats-top__cont-item" @click="togglePopover()" :class="backGroundColorActive">
                    <span class="chats-top__item-img-wrap">
                        <img width="19px" height="19px" :src="require(`@/assets/static/images/calendar1.svg`)" alt="calendar" />
                    </span>
                    <span class="date_picker">{{ text }}</span>
                    <span v-if="date" @click="assignDateClear" class="right_sidebar__assign_user_clear">
                        <img :src="require(`@/assets/static/images/assign-close.svg`)" alt="">
                    </span>
                </p>
            </template>
        </DatePicker>
    </div>
</template>

<script>
    import { mapMutations, mapActions, mapGetters } from "vuex";
    import DatePicker from 'v-calendar/lib/components/date-picker.umd'
    import {EventEmitter} from '../../main'

    export default {
        name: "SetDeadline",
        props: {
            task_id: {
                type: [String, Number],
                required: true,
            },
        },
        data() {
            return {
                text: "Set due date",
                date: '',
                backGroundColorActive: '',
                startWeek: 1,
            }
        },
        watch: {
            getRenderUserData(e) {
                this.render()
            },
            $route(e) {
                // setTimeout(() => {
                    this.render(e.params.task_id)
                // }, 100)
            }
        },
        methods: {
            check_project_id() {
                 if (this.$route.params.chat_id) {
                    let task_id = this.$route.params.task_id
                    let parent_position = null;
                    let all_ids = Object.keys(this.getTasks)
                    let tasks_group = Object.values(this.getTasks)

                    for (let id in tasks_group) {
                        let tasks = tasks_group[id]
                        if (tasks.length) {
                            for( let i in tasks ) {
                                if (tasks[i]._id == task_id) {
                                    parent_position = id
                                    break
                                }
                            }

                            if (parent_position == id) {
                                break
                            }
                        }
                    }

                    if (parent_position) {
                        this.project_id = all_ids[parent_position]
                    }

                }
            },
            onDateRangeChange: function () {

                this.check_project_id()

                this.$socket.emit('IN_AssignDate', {
                    date: new Date(this.date).getTime(),
                    task_id: this.task_id,
                    project_id: this.project_id
                })

            },
            assignDateClear: function () {
                this.check_project_id()
                this.$socket.emit('IN_AssignDate', {
                    date: '',
                    task_id: this.task_id,
                    project_id: this.project_id
                })
                this.text = "Set due date";
                this.backGroundColorActive = ''
                this.date = ''
            },
            render(task_id) {
                try {

                    let assign = this.getAssigns.find(e => e.task_id == this.$route.params.task_id)
                    if (!assign) return
                    if (assign.date) {

                        if (!isNaN(assign.date)) {
                            assign.date = new Date(+assign.date).toISOString()
                        }

                        this.date = assign.date
                        // this.text = assign.date ? this.$w_date_parse(assign.date) : "Set due date"

                        if (assign.date) {
                            let a = this.$w_date_parse(assign.date)
                            a = a.split(" ")
                            if (a.length == 1) {
                                this.text = a[0]
                            } else {
                                this.text = a[2] ? a[1].replace(',', '') + ' ' + a[0] + ', ' + a[2] : a[1].replace(',', '') + ' ' + a[0]
                            }
                        } else {
                            this.text = "Set due date"
                        }

                        const nowDate = new Date()
                        const dateLine = new Date(assign.date)

                        if (this.$w_date_parse(assign.date) == 'Today') {
                            this.backGroundColorActive = 'endTime'
                        } else {
                            if (nowDate.getTime() >= dateLine.getTime()) {
                                this.backGroundColorActive = 'endTime'
                            } else {
                                this.backGroundColorActive = 'hasTime'
                            }
                        }

                    } else {
                        this.text = "Set due date";
                        this.backGroundColorActive = ''
                        this.date = ''
                    }

                    if (this.getUserData.date_format && this.getUserData.date_format.start_of_the_week) {
                        if (this.getUserData.date_format.start_of_the_week == 'Sunday') {
                            this.startWeek = 1
                        }
                        if (this.getUserData.date_format.start_of_the_week == 'Monday') {
                            this.startWeek = 2
                        }
                    }   
                } catch (error) {
                    console.log(error)
                }
            }
        },
        mounted() {

            this.project_id = this.$route.params.project_id

            this.render()

            EventEmitter.$on('AssignDate', (data) => {
                if(data.date){
                    if (data.task_id == this.$route.params.task_id) {

                        if (!isNaN(data.date)) {
                            data.date = new Date(+data.date).toISOString()
                        }
                        
                        this.date = data.date
                        // this.text = data ? this.$w_date_parse(data.date) : "Set due date";
                        if (data.date) {
                            let a = this.$w_date_parse(data.date)
                            a = a.split(" ")
                            if (a.length == 1) {
                                this.text = a[0]
                            } else {
                                this.text = a[2] ? a[1].replace(',', '') + ' ' + a[0] + ', ' + a[2] : a[1].replace(',', '') + ' ' + a[0]
                            }
                        } else {
                            this.text = "Set due date"
                        }
                        const nowDate = new Date()
                        const dateLine = new Date(data.date)
                        // console.log(dateLine, data.date)
                        if(this.$w_date_parse(data.date) == 'Today'){
                            this.backGroundColorActive = 'endTime'
                        }else{
                            if (nowDate.getTime() > dateLine.getTime()) {
                                this.backGroundColorActive = 'endTime'
                            } else {
                                this.backGroundColorActive = 'hasTime'
                            }
                        }
                    }
                }else{
                    this.text = "Set due date";
                    this.backGroundColorActive = ''
                    this.date = ''
                }
                
            })
        
        },
        computed: {
            ...mapGetters(["getTasks", "getUserData", "getRenderUserData", "getAssigns"]),
        },
        components: {
            DatePicker
        }
    };
</script>
