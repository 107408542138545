<template>
  <div class="notes__block">
    <div 
      class="notes__block__top"
      @touchstart="startMove"
      @touchmove="moveEvent"
      @touchend="endMove"
    >
      <p>Add a note</p>
      <button @click.stop="saveAndCloseNotes">
        Save
      </button>
    </div>
    <div style="position: relative;height: calc(100% - 40px);width: 100%;">
      <textarea
        placeholder="Add a note..."
        id="editing"
        v-model="noteContent"
        spellcheck="false"
        ref="noteTextarea"
        @input="(e) => update(e.target.value.slice(0, 3000))"
        @scroll="sync_scroll"
        @click="handleClick"
        @mousemove="handleMouseOver"
      ></textarea>
      <pre
        ref="highlightingElement"
        @click="handleClick"
        @mousemove="handleMouseOver"
        id="highlighting"
      ><div id="highlighting-content" ref="notesHighlightingContent" @click="router_open_link"></div></pre>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import VueResizable from "vue-resizable";

export default {
  name: "Notes",
  props: {
    task_id: [String, Number],
    task_name: String
  },
  data() {
    return {
      edit: false,
      noteContent: "",
      showPlaceholder: true,
      noteEmpty: false,
      prevContent: "",
      active: ["b"],
      send: true
    };
  },
  mounted() {

    if (this.$route.params.project_id) {
      this.project_id = this.$route.params.project_id;
    } else {
      let projectID = localStorage.getItem("project_id");
      this.project_id = projectID ? projectID : this.$route.params.project_id;
    }
    this.set_note(this.note, this.task_id);
    this.prevContent = this.noteContent;
  },
  methods: {
    ...mapActions(["socket_send_note"]),
    saveAndCloseNotes() {
      this.sendNote()
      this.closeNotes()
    },
    closeNotes() {
      this.$emit('closeNotes')
    },
    startMove(e) {
      this.$emit('startMove', e)
    },
    moveEvent(e) {
      this.$emit('moveEvent', e)
    },
    endMove() {
      this.sendNote()
      this.$emit('endMove')
    },
    getNoteSize() {
      let height = 130
      setTimeout(() => {
        this.$w_taskData(this, this.$route.params.project_id).forEach((e, i) => {
          if (e._id == this.$route.params.task_id) {
            e.users.forEach((user) => {
              if (user._id == this.getUserData._id) {
                height = user.note_size
              }
            })
          }
        })
      }, 100)
      return height
    },
    endHandler(e) {
      document.querySelector('.resizable-component > .resizable-b').classList.remove('move')
      if (this.send) {
        this.send = false
        this.$socket.emit('IN_Note_Size', {
          project_id: this.$route.params.project_id,
          task_id: this.$route.params.task_id,
          size: e.height
        })
      }
    },
    moveHandler() {

      document.querySelector('.resizable-component > .resizable-b').classList.add('move')
      setTimeout(() => {
        document.querySelector('body').setAttribute('style', '')
      }, 1000)

    },
    handleMouseOver(e) {
      if (this.$refs.highlightingElement) {
        this.$refs.highlightingElement.style.zIndex = 3;
        const currentElement = document.elementFromPoint(e.pageX, e.pageY)
        this.$refs.highlightingElement.style.zIndex = 0;
        if (!currentElement.href) {
          this.$refs.noteTextarea.style.cursor = ''
        } else {
          this.$refs.noteTextarea.style.cursor = 'pointer'
          return
        }
      }
    },
    handleClick(e) {
      setTimeout(() => {
        const selection = window.getSelection().toString();
        if (selection !== "") return;
        if (this.$refs.highlightingElement) {
          this.$refs.highlightingElement.style.zIndex = 3;
        }
        const element = document.elementFromPoint(e.pageX, e.pageY);
        if (element.href) element.click();
        if (this.$refs.highlightingElement) {
          this.$refs.highlightingElement.style.zIndex = 0;
        }
      }, 0)
    },
    urlify(str) {
      // return str
      var urlRegex = /(http(s)?:\/\/[^\s]+)/g;

      str = str.replace(/</g, "&lt;");
      str = str.replace(/>/g, "&gt;");

      str = str.replace(urlRegex, function (url) {
        return (
          '<a href="' +
          url +
          '" target="_blank">' +
          url +
          "</a>"
        );
      });

      // for (let i = 25; i < str.lenght; i += 25) {
      //   str.splice(i, 0, "\n");
      // }

      return str;
    },
    update(text) {
      // let result_element = document.querySelector("#highlighting-content");
      let result_element = this.$refs.notesHighlightingContent
      // Handle final newlines (see article)
      if (text[text.length - 1] == "\n") {
        text += " ";
      }
      // Update code
      result_element.innerHTML = this.urlify(text); /* Global RegExp */
      // Syntax Highlight
    },
    sync_scroll(e) {
      const element = document.getElementById("editing");
      /* Scroll result to scroll coords of event - sync with textarea */
      let result_element = document.querySelector("#highlighting");
      // Get and set x and y
      result_element.scrollTop = element.scrollTop;
      result_element.scrollLeft = element.scrollLeft;
    },
    onEdit(e) {
      document.querySelector("textarea.right_sidebar__notes").focus();
      if (!e.path[0].href) {
        this.edit = true;
        setTimeout(() => {
          document.querySelector("textarea.right_sidebar__notes").focus();
        }, 100);
      }
    },
    sendNote() {
      if (this.prevContent === this.noteContent) return;
      this.check_project_id();
      const req = {
        task_id: this.task_id,
        notes: this.noteContent,
        project_id: this.project_id,
      };

      if (window.navigator.onLine) {
        this.socket_send_note(req);
      }
      this.prevContent = this.noteContent;
    },
    checkValue() {
      this.showPlaceholder = !this.noteContent;
    },
    goToNextLine(e) {
      var position = document.querySelector(".right_sidebar__notes")
        .selectionEnd;
      this.noteContent =
        this.noteContent.substring(0, position) +
        "<br/>" +
        this.noteContent.substring(position);

      setTimeout(() => {
        document.querySelector(".right_sidebar__notes").selectionEnd =
          position + 1;
        if (this.noteContent.length == position + 1) {
          document.querySelector(
            ".right_sidebar__notes"
          ).scrollTop = document.querySelector(
            ".right_sidebar__notes"
          ).scrollHeight;
        }
      }, 10);
    },
    check_project_id() {
      if (this.$route.params.chat_id) {
        let task_id = this.$route.params.task_id;
        let parent_position = null;
        let all_ids = Object.keys(this.getTasks);
        let tasks_group = Object.values(this.getTasks);

        for (let id in tasks_group) {
          let tasks = tasks_group[id];
          if (tasks.length) {
            for (let i in tasks) {
              if (tasks[i]._id == task_id) {
                parent_position = id;
                break;
              }
            }

            if (parent_position == id) {
              break;
            }
          }
        }

        if (parent_position) {
          this.project_id = all_ids[parent_position];
        }
      }
    },
    pareserFromBack(note) {
      if (note) {
        if (note.notes) {
          this.noteContent = note.notes;
        } else {
          this.noteEmpty = true;
          this.noteContent = "";
        }
      } else {
        this.noteEmpty = true;
        this.noteContent = "";
      }
      this.checkValue();
      this.update(this.noteContent);
    },
    set_note(notes, task_id) {
      const note = notes.find((n) => n.task_id == task_id);
      this.pareserFromBack(note);
    },
    router_open_link(e) {
        e.preventDefault()
        const link = e.target.closest("a")
        if (link) {
            if (link.origin == window.location.origin) {
                let url = link.href.replace(link.origin, '')
                this.$router.push(url).catch(()=>{})
            } else {
                window.open(link.href, '_blank')
            }
        }
    }
  },
  computed: {
    ...mapGetters([
      "get_notes", 
      "getTasks", 
      "get_flag_for_note", 
      "getUserData", 
      "getTasks",
      "getCompletedTasks"
    ]),
    note() {
      return this.$store.state.tasks.tasks_notes;
    },
  },
  watch: {
    send(e) {
      if (!e) {
        setTimeout(() => {
          this.send = true
        }, 50)
      }
    },
    getID(e) {
      this.set_note(this.note, e);
    },
    get_flag_for_note() {
      this.set_note(this.note, this.task_id);
    },
    noteContent(e) {
      if (e.length > 3000) {
        this.noteContent = e.slice(0, 3000);
      }
    },
    $route() {
      this.set_note(this.note, this.task_id);
    },
  },
  components: {
    VueResizable
  }
};
</script>

<style lang="scss">
#notes-holder {
  border-radius: 3px 3px 0 0;
  margin: 0;
}
#highlighting-content {
  border-radius: 3px 3px 0 0;
  white-space: pre-wrap;
  word-break: break-word;
  a {
    color: #489cdc;
  }
}
#editing,
#highlighting {
  border: 0;
  width: 300px;
  height: 100%;
  width: 100%;
  border-radius: 3px 3px 0 0;
  &::-webkit-scrollbar {
    width: 0;
  }
}
#editing,
#highlighting,
#highlighting * {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 21px;
  tab-size: 2;
  font-weight: 400;
}

// #editing,
#highlighting {
  position: absolute;
  top: 0;
  left: 0;
  overflow-x: auto;
}

#editing,
#highlighting-content {
  padding: 10px 15px;
}

#editing {
  z-index: 2;
  position: relative;
  outline: none;
}

#editing {
  color: transparent;
  background: transparent;
  caret-color: black;
}

#editing {
  resize: none;
}
.visual-content {
  a {
    display: inline;
  }
}

</style>
