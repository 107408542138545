<template>
    <main class="main-task-chats">

        <div class="right_col_sidebar" :class="openNotes ? 'bg-color' : ''">
            <div class="right_sidebar">

                <section class="chats-top-section">
                    <div class="chats-top">

                        <div class="chats-top__top">
                            <a class="chats-top-back" href="#" @click="handleBack">
                                <img :src="require(`@/assets/static/images/arrow_back.svg`)" alt="">
                            </a>
                            <textarea v-if="rename_input_show" class="full__name" @input="resize" @blur="rename_task"
                                @press.enter="rename_task">{{ task.name }}</textarea>
                            <p v-else @click="renameInputShow">{{ task.name }}</p>
                            <i>{{ task.name }}</i>
                            <button @click="renameInputShow">{{ rename_input_show ? 'Less' : 'More' }}</button>
                            <!-- <RenameTaskName
                                :task="task"
                                :project_id="task.project_id"
                                type="task"
                            ></RenameTaskName> -->
                        </div>

                        <div class="chats-top__cont">
                            <AssignUser :key="$route.params.task_id"></AssignUser>
                            <SetReminder :key="$route.params.task_id + 2" :task_id="$route.params.task_id"></SetReminder>
                            <SetDeadline :key="$route.params.task_id + 3" :task_id="$route.params.task_id"></SetDeadline>

                            <div class="chats-top__cont-item notes_mini" @click="openNotesAction">
                                <div class="chats-top__item-img-wrap"
                                    :style="note.notes.slice(0, 20) ? 'background-color: #0077FF' : ''">
                                    <img :src="require(`@/assets/static/images/pencil.svg`)" alt="">
                                </div>
                                <p>{{ note.notes.slice(0, 20) || 'Add a note' }}</p>
                            </div>

                        </div>
                    </div>
                </section>

                <div class="right_sidebar__bottom" id="right_sidebar__bottom">
                    <div class="right_sidebar__comments">
                        <el-tabs v-model="activeTabName" type="card" @tab-click="change_att_coment_block"
                            :before-leave="change_hook_tabs">
                            <el-tab-pane label="Comments" name="comment" class="is-active comments comments_list">
                                <Comments :key="$route.params.task_id" :date="task.date" :author="task.auth"
                                    :type="'list'" />
                                <AddComment :key="task._id + 1" :type="'list'" :id="task._id" />
                            </el-tab-pane>
                            <el-tab-pane label="Attachments" name="files">
                                <Files :key="task._id + 2" />
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </div>

            </div>
        </div>

        <section 
            class="notes" 
            :class="openNotes ? 'active' : ''" 
        >
            <Notes 
                :task_id="$route.params.task_id" 
                :task_name="task.name" 
                @closeNotes="clickSaveNoteButtonAction"
                @startMove="startMove"
                @moveEvent="moveEvent"
                @endMove="endMove"
            ></Notes>
        </section>

    </main>
</template>

<script>

import { mapGetters, mapMutations, mapActions } from "vuex"
import Comments from '../components/comment/Comments.vue'
import AddComment from '../components/comment/AddComment.vue'
import Files from "@/components/files/Files"
import AssignUser from "@/components/tasks/AssignUser"
import SetReminder from "@/components/tasks/SetReminder"
import SetDeadline from "@/components/tasks/SetDeadline"
import Notes from "@/components/tasks/Notes"
import RenameTaskName from "@/components/app/RenameTaskName"
import { EventEmitter } from '../main'

export default {
    data: () => ({
        activeTabName: "comment",
        openNotes: false,
        clickSaveNoteButton: false,
        rename_input_show: false,
        startY: 0,
        currentY: 0
    }),
    components: {
        Comments,
        AddComment,
        Files,
        AssignUser,
        SetReminder,
        SetDeadline,
        Notes,
        RenameTaskName
    },
    computed: {
        task() {
            if (!this.$store.state.tasks.tasks[this.$route.params.project_id]) return {}
            if (this.$store.state.tasks.tasks[this.$route.params.project_id].find(e => e._id == this.$route.params.task_id)) {
                return this.$store.state.tasks.tasks[this.$route.params.project_id].find(e => e._id == this.$route.params.task_id)
            } else {
                return this.$store.state.tasks.completed_tasks[this.$route.params.project_id].find(e => e._id == this.$route.params.task_id)
            }
        },
        messages() {
            return this.$store.state.comments.comments[this.$route.params.task_id] || []
        },
        note() {
            let data = this.$store.state.tasks.tasks_notes.find((n) => n.task_id == this.$route.params.task_id)
            return data || { notes: '' }
        },
        user() {
            return this.$store.state.key.user;
        },
    },
    methods: {
        ...mapActions([
            "socket_rename_task",
        ]),
        clickSaveNoteButtonAction() {
            this.clickSaveNoteButton = true
            this.openNotes = false
            document.querySelector(".notes").style.bottom = `-100%`;
            setTimeout(() => this.clickSaveNoteButton = false, 500)
        },
        startMove(e) {
            document.querySelector(".main-task-chats").classList.add('noanime')
            this.startY = e.touches[0].clientY
            this.currentY = this.startY
        },
        moveEvent(e) {
            const slideElement = document.querySelector(".notes")
            const touch = e.touches[0]
            this.currentY = touch.clientY
            const deltaY = this.currentY - this.startY

            if (deltaY < 10) {
                this.openNotes = true
            } else {
                this.openNotes = false
            }

            slideElement.style.bottom = `-${deltaY}px`;

            e.preventDefault()
        },
        endMove() {
            document.querySelector(".main-task-chats").classList.remove('noanime')
            if (!this.clickSaveNoteButton) {
                const slideElement = document.querySelector(".notes")
                if (this.currentY - this.startY < 10) {
                    this.openNotes = true
                } else {
                    this.openNotes = false
                    slideElement.style.bottom = `-100%`;
                }
            }
            this.clickSaveNoteButton = false
        },
        openNotesAction() {
            this.openNotes = !this.openNotes
            // if (this.openNotes) {
            //     // document.querySelector('body').classList.add('open-note')
            //     // document.querySelector('#editing').focus()
            // } else {
            //     // document.querySelector('body').classList.remove('open-note')
            // }
        },
        handleBack(e) {
            e.preventDefault()
            if (this.user.shortcut_inbox == this.$route.params.project_id) {
                this.$router.push("/inbox/" + this.$route.params.project_id)
            } else {
                this.$router.push("/project/" + this.$route.params.project_id)
            }
        },
        resize() {
            let element = document.querySelector('.full__name')
            element.style.height = "1px"
            element.style.height = (18 + element.scrollHeight) + "px"
        },
        change_att_coment_block(tab, event) {
            EventEmitter.$emit('Files')
        },
        change_hook_tabs(activeName, oldActiveName) {
            document.getElementById(`pane-${activeName}`).classList.add("is-active")
            if (activeName == "files") {
                document.querySelectorAll(`.el-tab-pane`).forEach((element) => {
                    element.classList.remove("default_value")
                })
            }
            document.getElementById(`pane-${oldActiveName}`).classList.remove("is-active")
        },
        renameInputShow() {
            this.rename_input_show = !this.rename_input_show
            if (this.rename_input_show) {
                setTimeout(() => {
                    let element = document.querySelector('.full__name')
                    element.style.height = "1px"
                    element.style.height = (18 + element.scrollHeight) + "px"
                    // element.focus()
                }, 100)
            }
        },
        rename_task($event) {
            let value = $event.target.value.trim()
            if (value != '') {
                if (value != this.task.name) {
                    this.socket_rename_task({
                        project_id: this.task.project_id,
                        task_id: this.task._id,
                        name: value.slice(0, 160)
                    })
                }
            }
            // this.init()
        },
        init() {
            let block = document.querySelector('.chats-top__top p') ? document.querySelector('.chats-top__top p') : document.querySelector('.chats-top__top textarea')
            if (document.querySelector('.chats-top__top i').clientWidth < block.clientWidth) {
                document.querySelector('.chats-top__top button').style.zIndex = '-1'
                this.rename_input_show = true
            } else {
                document.querySelector('.chats-top__top button').style = ''
                this.rename_input_show = false
            }
        }
    },
    mounted() {
        this.init()
    }
}

</script>
