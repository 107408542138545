<template>
    <div :style="$route.matched[0].name != 'Inbox' ? '' : 'width: 49.5%'">
        <DatePicker 
            v-model="date" 
            mode="dateTime" 
            :first-day-of-week="startWeek" 
            locale="en" 
            :is12hr="user_data.date_format.time == '1:00 PM' ? true : false"
            :is24hr="user_data.date_format.time == '13:00' ? true : false" 
            @input="onDateRangeChange"
            @click="open"
        >
            <template v-slot="{ inputValue, togglePopover }">
                <div class="chats-top__cont-item" @click="togglePopover()" :class="backGroundColorActive">
                    <span class="chats-top__item-img-wrap">
                        <img width="19px" height="19px" :src="require(`@/assets/static/images/alarm1.svg`)" alt="alarm" />
                    </span>
                    <span class="remind_value focus:outline-none focus:border-blue-300" :value="inputValue">
                        <p v-if="text">{{text}}</p>
                        <div v-else>
                            <p class="remind_time">{{v_time}}</p>
                            <p class="remind_date">{{v_date}}</p>
                        </div>
                    </span>
                    <span v-if="date" @click="assignRemindClear" class="right_sidebar__assign_user_clear">
                        <img :src="require(`@/assets/static/images/assign-close.svg`)" alt="">
                    </span>
                </div>
            </template>
        </DatePicker>
    </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import {EventEmitter} from '../../main'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import moment from 'moment'

export default {
    name: "SetDeadline",
    props: {
        task_id: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            text: "Remind me",
            date: '',
            backGroundColorActive: '',
            v_time: '',
            v_date: '',
            startWeek: 1,
            user_data: {
                date_format: {
                    time: ''
                }
            },
            timezones_plus: [
                'Europe/Amsterdam', // +1
                'Europe/Athens', // +2
                'Europe/Moscow', // +3
                'Indian/Mahe', // +4
                'Asia/Ashgabat', // +5
                'Asia/Dhaka', // +6
                'Asia/Bangkok', // +7
                'Asia/Hong_Kong', // +8
                'Asia/Pyongyang', // +9
                'Australia/Sydney', // +10
                'Asia/Magadan', // +11
            ],
            timezones_minus: [
                'Atlantic/Azores', // -1
                'Brazil/DeNoronha', // -2,
                'America/Buenos_Aires', // -3
                'America/Aruba', // -4
                'America/New_York', // -5
                'America/Chicago', // -6
                'America/Boise', // -7
                'America/Los_Angeles', // -8
                'America/Anchorage', // -9
                'US/Hawaii', // -10
                'Pacific/Niue', // -11
            ],
        };
    },
    methods: {
        check_project_id() {
            if (this.$route.params.chat_id) {
                let task_id = this.$route.params.task_id
                let parent_position = null;
                let all_ids = Object.keys(this.getTasks)
                let tasks_group = Object.values(this.getTasks)

                for (let id in tasks_group) {
                    let tasks = tasks_group[id]
                    if (tasks.length) {
                        for( let i in tasks ) {
                            if (tasks[i]._id == task_id) {
                                parent_position = id
                                break
                            }
                        }

                        if (parent_position == id) {
                            break
                        }
                    }
                }

                if (parent_position) {
                    this.project_id = all_ids[parent_position]
                }

            }
        },
        render() {

            try {

                if (this.getUserData.date_format.start_of_the_week) {
                    if (this.getUserData.date_format.start_of_the_week == 'Sunday') {
                        this.startWeek = 1
                    }
                    if (this.getUserData.date_format.start_of_the_week == 'Monday') {
                        this.startWeek = 2
                    }
                }

                this.getProjects.forEach((project) => {
                    if (project._id == this.project_id) {
                        this.getTasks[project._id].forEach((task) => {
                            if (task._id == this.$route.params.task_id) {
                                task.users.forEach((user) => {
                                    if (user._id == this.getUserData._id) {
                                        if (user.remind) {
                                            let date_remind = user.remind
                                            if (!isNaN(user.remind)) {
                                                date_remind = new Date(+user.remind).toISOString()
                                            }

                                            let remind = this.timezone(date_remind)
                                            
                                            this.text = ''
                                            this.date = remind

                                            // const array_date = remind.split(',')
                                            this.v_date = this.$w_date_parse(date_remind)
                                            this.v_time = this.$w_time_parse(date_remind, this.getUserData.date_format.time)

                                            const nowDate = new Date()
                                            const dateLine = new Date(remind)

                                            if (nowDate.getTime() >= dateLine.getTime()) {
                                                this.backGroundColorActive = 'endRemind'
                                            } else {
                                                this.backGroundColorActive = 'hasRemind'
                                            }

                                        } else {
                                            this.text = "Remind me";
                                            this.backGroundColorActive = ''
                                            this.date = ''
                                        }
                                    }
                                })
                            }
                        })
                    }
                })

            } catch (error) {
                console.log(error)
            }

        },
        assignRemindClear: function () {
            this.check_project_id()

            let taskName = null,
                project_id = this.$route.params.project_id

            if (this.getTasks)
            {
                if (this.getTasks[project_id])
                {
                    this.getTasks[project_id].forEach(task => {
                        // console.log('task:')
                        // console.log(task)
                        if (task)
                        {
                            if (task._id == this.task_id)
                            {
                                taskName = task.name
                            }
                        }
                    })
                }
            }
            this.removeReminderFromStorage(this.date, this.$route.params.project_id, this.task_id, taskName);

            this.$socket.emit('IN_AssignRemind', {
                remind: '',
                task_id: this.task_id,
                project_id: this.project_id
            })  
            this.text = "Remind me";
            this.backGroundColorActive = ''
            this.date = ''
        },
        onDateRangeChange () {

            // document.querySelector('.vc-time-picker.vc-bordered').append("<button class='vc-time-picker__set'>set</button>")

            let taskName = null,
                project_id = this.$route.params.project_id,
                is_done = false

            // console.log(this.getCompletedTasks)
            if (this.getCompletedTasks)
            {
                if (this.getCompletedTasks[project_id])
                {
                    this.getCompletedTasks[project_id].forEach( cur_task => {
                        if (cur_task)
                        {
                            if (cur_task._id == this.task_id)
                            {
                                is_done = true
                            }
                        }
                    })
                }
            }

            if (! is_done && this.getTasks)
            {
                if (this.getTasks[project_id])
                {
                    this.getTasks[project_id].forEach(task => {
                        // console.log('error in task:')
                        // console.log(task)
                        if (task)
                        {
                            if (task._id == this.task_id)
                            {
                                taskName = task.name
                            }
                        }

                    })
                }

                this.addReminderToStorage(this.date, this.$route.params.project_id, this.task_id, taskName);
            }

            this.check_project_id()

            this.$socket.emit('IN_AssignRemind', {
                remind: new Date(this.date).getTime(),
                task_id: this.task_id,
                project_id: this.project_id
            })
        },
        addReminderToStorage: (_date, _project_id, _task_id, _task_name) => {
            // console.log('add to local storage')
            // console.log(' date, project_id, task_id, task_name ')
            // console.log(_date, _project_id, _task_id, _task_name)

            let rDate = new Date(_date),
                divider = '&$&',
                divider2 = '!|!'

            if (localStorage.reminders)
            {
                if (localStorage.reminders.includes(_project_id + divider + _task_id))
                {
                    if (rDate) 
                    {
                        let tmpReminders = localStorage.reminders.split(divider2)
                        tmpReminders.forEach((elem, i) =>
                        {
                            // console.log(elem)
                            if (elem.includes(_project_id + divider + _task_id))
                            {
                                tmpReminders[i] = _project_id + divider + _task_id + divider + _task_name + '_time_' + rDate.getTime()
                            }
                        });
                        // console.log(tmpReminders)
                        localStorage.reminders = tmpReminders.join(divider2)
                    }
                    
                }
                else 
                {
                    localStorage.reminders += divider2 + _project_id + divider + _task_id + divider + _task_name + '_time_' +  rDate.getTime()
                }
            }
            else
            {
                if (_task_name)
                {
                    localStorage.reminders = _project_id + divider + _task_id + divider + _task_name + '_time_' +  rDate.getTime()  
                }
            }

        },
        removeReminderFromStorage: (_date, _project_id, _task_id, _task_name) => {

            let rDate = new Date(_date),
                divider = '&$&',
                divider2 = '!|!'

            if (localStorage.reminders)
            {
                if (localStorage.reminders.includes(_project_id + divider + _task_id))
                {
                    if (rDate) 
                    {
                        let tmpReminders = localStorage.reminders.split(divider2),
                            newReminders = []
                        tmpReminders.forEach((elem, i) =>
                            {
                                // console.log(elem)
                                if (! elem.includes(_project_id + divider + _task_id))
                                {
                                    newReminders.push(elem)
                                }
                            });
                        // console.log(tmpReminders)
                        localStorage.reminders = newReminders.join(divider2)
                    }
                    
                }
            }

        },
        timezone: function (d) {

            function convertTZ(date, tzString) {
                return new Date(date).toLocaleString('en-US', { timeZone: tzString })  
            }

            function getTimeZone() {
                var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
                return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2)
            }

            let time_zone = getTimeZone()
            let number_time_plus = time_zone.replace('+', '')
            let number_time_minus = time_zone.replace('-', '')

            if(time_zone == 0) {
                return convertTZ(d, 'UTC')
            } else if (time_zone > 0) {
                return convertTZ(d, this.timezones_plus[Number(number_time_plus - 1)])
            } else if (time_zone < 0) {
                return convertTZ(d, this.timezones_minus[Number(number_time_minus - 1)])
            }

        },
        open() {
            console.log(0)
        }
    },
    mounted() {

        if (this.$route.params.project_id) {
            this.project_id = this.$route.params.project_id
        } else {
            let projectID = localStorage.getItem('project_id')
            this.project_id = projectID ? projectID : this.$route.params.project_id
        }

        this.user_data = this.getUserData
        this.check_project_id()
        this.render()
    
        EventEmitter.$on('AssignRemind', (data) => {
            if(data.remind){
                
                let task_name = ''
                let is_done = false

                if (this.getCompletedTasks[data.project_id])
                {
                    this.getCompletedTasks[data.project_id].forEach(cur_task => {
                      if (cur_task._id == this.task_id)
                      {
                        is_done = true
                      }
                    })
                }

                if (! is_done)
                {
                    if (this.getTasks)
                    {
                        if (this.getTasks[data.project_id])
                        {
                            this.getTasks[data.project_id].forEach(task => {
                                if (task)
                                {
                                    if (task._id == this.task_id)
                                    {
                                        task_name = task.name
                                    }
                                }
                            })
                        }
                    }

                    let date_remind = data.remind
                    if (!isNaN(data.remind)) {
                        date_remind = new Date(+data.remind).toISOString()
                    }

                    this.addReminderToStorage( date_remind, data.project_id, data.task_id, task_name )
                }

                if (data.task_id == this.$route.params.task_id) {

                    if (!isNaN(data.remind)) {
                        data.remind = new Date(+data.remind).toISOString()
                    }

                    let remind = this.timezone(data.remind)

                    this.text = ''
                    this.date = remind

                    const array_date = remind.split(',')
                    this.v_date = this.$w_date_parse(data.remind)
                    this.v_time = this.$w_time_parse(data.remind, this.getUserData.date_format.time)

                    const nowDate = new Date()
                    const dateLine = new Date(remind)

                    if (nowDate.getTime() >= dateLine.getTime()) {
                        this.backGroundColorActive = 'endRemind'
                    } else {
                        this.backGroundColorActive = 'hasRemind'
                    }
                }
            }else{
                if (data.remind == '' )
                {
                    let task_name = ''
                    if (this.getTasks)
                    {
                        if (this.getTasks[data.project_id])
                        {
                            this.getTasks[data.project_id].forEach(task => {
                                if (task)
                                {
                                    if (task._id == this.task_id)
                                    {
                                        task_name = task.name
                                    }
                                }
                            })
                        }
                    }
                    this.removeReminderFromStorage( data.remind, data.project_id, data.task_id, task_name )
                }
                this.text = "Remind me";
                this.backGroundColorActive = ''
                this.date = ''
            }
        })
    
    },
    computed: {
        ...mapGetters(["getTasks", "getUserData", "getRenderUserData", "getProjects", "getCompletedTasks"]),
    },
    components: {
        DatePicker
    },
    watch: {
        getRenderUserData(e) {
            this.check_project_id()
            this.render()
        }
    },
};

</script>
