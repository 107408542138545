<template>
    <div class="comments_block" ref="comments_block" :id="'comments_block_' + type" @scroll="scroll_date_sticky($event)"
        :class="{ 'position-static': loading }">

        <span class="task_created" :class="{ 'task_created-loading': loading }">{{ created() }}</span>

        <div v-if="lazy_load_loader" style="text-align: center">
            <!-- <span class="image-pre-loader image-pre-loader-red"></span> -->
            <div class="spinner_anime">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>

        <template v-if="!loading">
            <div v-for="(section) in comments" :key="section.id"
                :class="[section.values[0] && section.values[0].date_for_chat ? 'date-comment-wrapper' : 'comment-message-wrapper']">
                <template v-if="section.values[0] && section.values[0].date_for_chat">
                    <div class="comment_date">
                        <span>{{ section.values[0].date_for_chat }}</span>
                    </div>
                </template>
                <template v-else>
                    <div class="container-block-for-user" :key="section.id + 99999999" v-if="section.values[0]">
                        <div class="avatar-container"
                            :class="['avatar-container_' + type, section.values[0].user_id == getUserData._id ? 'own-comment-avatar own-comment-avatar-' + type : 'avatar-container-guest avatar-container-guest-' + type]">
                            <div v-if="section.values[0].user_id" class="comment__avatar"
                                :style="'background-color: ' + get_user(section.values[0].user_id).color"
                                :key="section.id + 99999"
                                v-html="$w_user_image(get_user(section.values[0].user_id), 70, true)"></div>
                        </div>
                        <div v-for="comment in section.values" :key="comment._id" :data-id="`comment__${comment._id}`"
                            :id="`comment__${comment._id}`" :class="[comment.class, 'comment_' + type]">

                            <div class="comment_body">
                                <div :class="is_file(comment)" @touchstart="event => startDownPress(comment, event)"
                                    @touchend="upPress">

                                    <!-- <div v-if="type == 'chat'"> -->
                                        <ul class="comment_read_user" v-if="getPlan.show_who_read_message == 'on' && type == 'chat'">
                                            <li v-for="user of chat.users" :key="user.user_id"
                                                v-show="user.read_last_message == comment._id"
                                                :data-user="user.read_last_message" :data-id="comment._id">
                                                <div :style="'background-color:' + get_user(user.user_id).color"
                                                    v-html="$w_user_image(get_user(user.user_id), 70, false)"></div>
                                                <span>{{ get_user(user.user_id).name }} seen this</span>
                                            </li>
                                        </ul>
                                    <!-- </div> -->

                                    <span v-if="comment.error" class="comment_body__error">
                                        <svg height="32" style="overflow:visible;enable-background:new 0 0 32 32"
                                            viewBox="0 0 32 32" width="32" xml:space="preserve"
                                            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                            <g>
                                                <g id="Error_1_">
                                                    <g id="Error">
                                                        <circle cx="16" cy="16" id="BG" r="16" style="fill:#D72828;" />
                                                        <path d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z"
                                                            id="Exclamatory_x5F_Sign" style="fill:#E6E6E6;" />
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </span>

                                    <div v-if="comment.user_id != getUserData._id && comment._id == section.values[0]._id"
                                        class="w-100 friends__comments">
                                        <span class="comment_text__user_name"
                                            :style="'color: ' + get_user(comment.user_id).color + ' !important'">
                                            {{ get_user(comment.user_id).name }}
                                        </span>
                                    </div>

                                    <div v-if="comment.reply && comment.reply._id" class="reply-container"
                                        :class="comment.user_id != getUserData._id ? 'friends__comments' : ''"
                                        style="width: 100%;min-width: 120px;">
                                        <div class="comment_text__reply" @click="go_to_comment(comment.reply._id)">
                                            <div v-if="comment.reply.files && comment.reply.files.length > 0"
                                                class="addcomment_reply__img">
                                                <div
                                                    v-if="comment.reply.files[0].ext.toLowerCase() == 'png' || comment.reply.files[0].ext.toLowerCase() == 'jpg' || comment.reply.files[0].ext.toLowerCase() == 'jpeg'">
                                                    <img v-if="replyImage.find(e => e._id == comment.reply._id)"
                                                        :src="replyImage.find(e => e._id == comment.reply._id).url">
                                                </div>
                                            </div>
                                            <div
                                                :style="comment.reply.files && comment.reply.files.length > 0 ? 'display: inline-block;position: relative;' : 'display: inline-block;width: inherit;'">
                                                <span v-if="comment.reply.user_id == getUserData._id"
                                                    class="comment_text__user_name reply__name green-name">
                                                    {{ get_user(comment.reply.user_id).name }}
                                                </span>
                                                <span v-else class="comment_text__user_name reply__name">
                                                    {{ get_user(comment.reply.user_id).name }}
                                                </span>
                                                <span class="addcomment_reply" v-if="comment.reply.files">
                                                    <div class="addcomment_reply__flex">
                                                        <div v-for="(file, i) in comment.reply.files" :key="file._id">
                                                            <div class="addcomment_reply__ext"
                                                                :style="i == 0 ? '' : 'display: none'">
                                                                <span>
                                                                    {{ file.filename.replace(file.ext,
                                                                        '').replace(file.filename.replace(file.ext,
                                                                            '').substr(-3), '') }}
                                                                </span>
                                                                {{ file.filename.replace(file.ext,
                                                                    '').substr(-3)[file.filename.replace(file.ext,
                                                                        '').substr(-3).length - 1] == '.' ?
                                                                    file.filename.replace(file.ext, '').substr(-3) + file.ext :
                                                                    file.filename.replace(file.ext, '').substr(-3) + '.' +
                                                                    file.ext }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                            <div class="comment_text__body reply__body">
                                                <span v-html="commentParseMentions(comment.reply.message)"></span>
                                                <!-- ... -->
                                                <!-- <span>{{comment.reply.message}}</span> -->
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="comment.files && comment.files.length > 0 && !comment.remove_file"
                                        class="comment_text__file attachment"
                                        :style="!comment.reply ? '' : comment.reply.user_id ? 'padding-top: 7px;' : ''">
                                        <div v-for="file in comment.files" :key="file._id"
                                            class="comment_text__file_body attachment_body">
                                            <DocFile :setEncodedImage="setEncodedImage(file)" :type="type" :src="'chat'"
                                                :file="file" :user_name="get_user(comment.user_id).name"
                                                :comment="comment" />
                                        </div>
                                    </div>

                                    <div class="comment_text__body" :ref="'comment_' + comment._id">
                                        <div v-if="comment.message" :style="comment.style">
                                            <span v-html="comment_parse(comment.message)" @click="router_open_link"
                                                class="comment_text__body__span"
                                                :class="comment.remove_file ? 'remove_file' : ''"></span>
                                            <div class="block"
                                                :style="getUserData.date_format.time == '1:00 PM' ? 'width: 62px;' : 'width: 49px;'">
                                            </div>
                                        </div>
                                        <span class="comment_text__time">
                                            <span :class="check_file_ext(comment) ? 'bg-color' : ''">
                                                {{ $w_time_parse(comment.date, getUserData.date_format.time) }}
                                                <div v-if="comment.user_id == getUserData._id"
                                                    style="display: inline-block">
                                                    <svg v-if="comment.send == false" xmlns="http://www.w3.org/2000/svg"
                                                        x="0px" y="0px" width="10" height="10" viewBox="0 0 24 24"
                                                        style=" fill:#a5a7a8;">
                                                        <path
                                                            d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 6 L 11 12.414062 L 15.292969 16.707031 L 16.707031 15.292969 L 13 11.585938 L 13 6 L 11 6 z">
                                                        </path>
                                                    </svg>
                                                    <div v-else :class="comment.read ? 'check__blue' : ''">
                                                        <i class="el-icon-check"></i>
                                                        <i v-if="comment.read" class="el-icon-check readed_message"></i>
                                                    </div>
                                                </div>
                                            </span>
                                        </span>
                                    </div>

                                </div>

                                <div v-if="type == 'chat'">
                                    <ul class="comment_read_user bottom_message"
                                        v-if="getPlan.show_who_read_message == 'on'">
                                        <li v-for="user of chat.users" :key="user.user_id"
                                            v-show="user.read_last_message == comment._id">
                                            <div :style="'background-color:' + get_user(user.user_id).color"
                                                v-html="$w_user_image(get_user(user.user_id), 70, false)"></div>
                                            <span>{{ get_user(user.user_id).name }} seen this</span>
                                        </li>
                                    </ul>
                                </div>

                            </div>

                        </div>
                    </div>
                </template>
            </div>
        </template>
        <!-- loading -->
        <div v-if="loading" class="spinner-holder spinner-holder-comment">
            <!-- <div>
                <span class="image-pre-loader image-pre-loader-red"></span>
            </div> -->
            <div class="spinner_anime">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
        <button v-if="shouldShowGoToLastBtn" class="go_to_last_message_btn" @click="scroll_bottom">
            <div class="go_to_last_message_btn__number" :style="{
                width: unreadMessageCache.size < 10 ? '18px' : unreadMessageCache.size < 100 ? '23px' : '28px',
                height: unreadMessageCache.size < 10 ? '18px' : '22px',
                lineHeight: unreadMessageCache.size < 10 ? '111%' : '135%',
                transform: unreadMessageCache.size < 10 ? 'translate(-60%, -5px)' : 'translate(-60%, -8px)'
            }">
                <p :key="random">{{ unreadMessageCache.size }}</p>
            </div>
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_1_3)">
                    <rect width="44" height="44" rx="22" fill="white" />
                    <g clip-path="url(#clip0_1_3)">
                        <path
                            d="M32.6563 17H30.5078C30.3617 17 30.2242 17.0537 30.1383 17.1418L22 25.5551L13.8617 17.1418C13.7758 17.0537 13.6383 17 13.4922 17H11.3438C11.1576 17 11.0487 17.159 11.1576 17.2729L21.2581 27.7164C21.6247 28.0945 22.3753 28.0945 22.7391 27.7164L32.8396 17.2729C32.9513 17.159 32.8425 17 32.6563 17Z"
                            fill="#FF8686" />
                    </g>
                </g>
                <defs>
                    <filter id="filter0_d_1_3" x="0" y="0" width="48" height="48" filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha" />
                        <feOffset dx="2" dy="2" />
                        <feGaussianBlur stdDeviation="1" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_3" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_3" result="shape" />
                    </filter>
                    <clipPath id="clip0_1_3">
                        <rect width="22" height="11" fill="white" transform="translate(11 17)" />
                    </clipPath>
                </defs>
            </svg>
        </button>

    </div>
</template>

<script>

import { mapGetters, mapActions, mapMutations } from "vuex"
import { EventEmitter } from '../../main'
import File from '../../utils/aws'
import DocFile from "./DocFile"
import MenuAction from './MenuAction.vue'

export default {
    name: "Comments",
    props: {
        // all_messages: {
        //     type: Array
        // },
        date: {
            type: [String, Number]
        },
        author: {
            type: String
        },
        type: {
            type: String
        }
    },
    data() {
        return {
            comments: [],
            loading: true,
            noSendMessages: [],
            sendMessages: [],
            errorSendMessages: [],
            unreadMessageCache: new Map(),
            shouldShowGoToLastBtn: false,
            windowActive: true,
            users: [],
            random: Math.random(),
            sendMessagesBool: true,
            chat: '',
            task: '',
            lazy_load_num: 70,
            lazy_load_loader: false,
            move: true,

            time: 0,
            interval_ended: false,
            menu_opened: false,
            replyImage: []
        }
    },
    computed: {
        ...mapGetters([
            "getUserData",
            "getKnownUsers",
            "getComments",
            "getChats",
            "getCommentLoading",
            "getRender",
            "getTasks",
            "getCompletedTasks",
            "getJumpToMessage",
            "getListsMentions",
            "getChatsMentions",
            "getUsersByProject",
            "getUsersByChat",
            "getPlan",
            "getFetchedComments",
            "getChatFetchedComments",
            "getApplicationStatus",
            "getCommentAdd",
            "get_reloadData",
            "getIsAppInForeground"
        ]),
        usersByEmail() {
            let allUsers = this.getUsersByChat[this.$route.params.chat_id]
            if (!allUsers) {
                allUsers = this.getUsersByProject[this.$route.params.project_id]
            }
            let users = {}
            if (!allUsers) return {}
            for (let i = 0; i < allUsers.length; i++) {
                const name = allUsers[i].name ? allUsers[i].name : allUsers[i].user_data.name
                const email = allUsers[i].user_data ? allUsers[i].user_data.email : allUsers[i].email
                users['@' + email] = name
            }
            this.comments_render()
            return users
        },
        get_messages() {
            if (this.type == 'list') {
                return this.$store.state.comments.comments[this.$route.params.task_id] || []
            } else if (this.type == 'chat') {
                return this.chat_comments()
            }
        }
    },
    components: {
        DocFile,
        MenuAction
    },
    watch: {
        getCommentAdd() {
            this.comments = []
            this.comments_render()
        },
        getCommentLoading() {
            if (document.hasFocus()) {
                this.comment_read()
            }
        },
        getRender() {
            if (document.hasFocus()) {
                this.comment_read()
            }
        },
        $route() {
            this.comments = []
            this.comments_render()
        },
        getJumpToMessage(e) {
            setTimeout(() => {
                this.toScroll = false
                this.go_to_comment(this.getJumpToMessage)
            }, 1000)
        },
        get_reloadData() {
            this.comments = []
            this.comments_render()
        },
        getIsAppInForeground() {
            if (!this.getIsAppInForeground) {
                this.comment_read()
            }
        }
    },
    async mounted() {
        this.$root.$on("selectMessageClose", () => {
            this.menu_opened = false;
            this.time = 0;
            this.interval_ended = true;
        })

        const { project_id, task_id, chat_id } = this.$route.params

        if (this.type == 'list') {

            let task
            let completed_task

            if (this.getTasks[project_id]) {
                task = this.getTasks[project_id].find(task => task._id == task_id)
            }

            if (this.getCompletedTasks[project_id]) {
                completed_task = this.getCompletedTasks[project_id].find(task => task._id == task_id)
            }

            if (task) {
                this.task = task
                if (task.hasMessage) {
                    if (!this.getFetchedComments[task_id]) {
                        await this.fetchComment({ list_id: project_id, task_id, type: 'list' }).then(res => this.loading = false)
                    } else {
                        this.loading = false
                    }
                } else {
                    this.loading = false
                }
            } else {
                this.task = completed_task
                if (!completed_task) return
                if (completed_task.hasMessage) {
                    if (!this.getFetchedComments[task_id]) {
                        await this.fetchComment({ list_id: project_id, task_id, type: 'list' }).then(res => this.loading = false)
                    } else {
                        this.loading = false
                    }
                } else {
                    this.loading = false
                }
            }

        } else if (this.type == 'chat') {

            const chat = this.getChats.find(chat => chat._id == chat_id)
            this.chat = chat

            if (chat) {
                if (chat.hasMessage) {
                    if (chat && !(chat._id in this.getChatFetchedComments)) {
                        await this.fetchComment({ chat_id, type: 'chat' }).then(res => this.loading = false)
                    } else {
                        this.loading = false
                    }
                } else {
                    this.loading = false
                }
            }

        }

        this.comments_render()
        this.scroll_bottom()

        window.onblur = () => {
            this.windowActive = false
        }
        window.onfocus = () => {
            this.windowActive = true
        }

        this.comment_read()

        window.onresize = (e) => {
            this.window_resize()
        }

        EventEmitter.$on('WindowResizeChat', (data) => {
            // if (this.type == 'chat') {
            this.window_resize()
            // }
        })

        EventEmitter.$on('noSendMessages', (data) => {
            this.noSendMessages = data
        })

        EventEmitter.$on('sendMessages', (data) => {
            this.sendMessages.push(data)
        })

        EventEmitter.$on('errorSendMessages', (data) => {
            this.errorSendMessages = data
        })

        EventEmitter.$on('scrollChatBody', (data, userId, messageId) => {
            if (!((data == this.$route.params.task_id && this.type == 'list') || (data == this.$route.params.chat_id && this.type == 'chat'))) return

            this.messageId = 'commnet_' + messageId
            if (this.isLastCommentInViewport()) {
                this.scroll_bottom()
            } else if (userId !== this.getUserData._id) {
                this.unreadMessageCache.set(messageId, true)
                this.shouldShowGoToLastBtn = true
                this.setObserver(messageId)
            }
        })

        EventEmitter.$on('deleteComment', (messageId) => {
            this.unreadMessageCache.delete(messageId)
            if (this.unreadMessageCache.size == 0) this.shouldShowGoToLastBtn = false
        })

    },
    methods: {
        ...mapActions([
            "socket_read_message",
            "fetchComment"
        ]),
        ...mapMutations([
            "setJumpToMessage",
            "setMessage",
            "removeMentionForList",
            "removeMentionForChat"
        ]),
        async checked_read_comment(comment) {
            let bool = false
            for await (let user of this.task.users) {
                if (user.read_last_message) {
                    let msg = this.getComments[this.$route.params.task_id].find(a => a._id == user.read_last_message)
                    if (msg.date >= comment.date) {
                        bool = true
                    }
                }
            }
            return bool
        },
        check_read(comment) {
            let bool = false
            if (this.type == 'list') {
                this.task.users.forEach((user, i) => {
                    if (user.read_last_message) {
                        let msg = this.getComments[this.$route.params.task_id].find(a => a._id == user.read_last_message)
                        if (msg) {
                            if (msg.date >= comment.date) {
                                bool = true
                            }
                        }
                    }
                })
            } else {
                bool = comment.read
            }
            return bool
        },
        setEncodedImage(img) {
            return function (base64) {
                img.base64 = base64
            }
        },
        Users(users, comment) {
            return users.filter(e => e.read_last_message == comment._id)
        },
        ShowUserName($event) {
            $event.target.nextSibling.style.display = 'block'
        },
        HideUserName($event) {
            $event.target.nextSibling.style.display = 'none'
        },
        get_users_read(users) {
            let user = users.filter(e => e != this.getUserData._id)
            return user.length > 0
        },
        setObserverForMentions() {

            const { project_id, task_id, chat_id } = this.$route.params

            if (this.type == 'list') {
                if (!this.getListsMentions[project_id]) return
                if (this.getListsMentions[project_id].mentions == 0) return
                if (!this.getListsMentions[project_id][task_id]) return
                if (this.getListsMentions[project_id][task_id].mentions == 0) return
            } else if (this.type == 'chat') {
                if (!this.getChatsMentions[chat_id]) return
                if (this.getChatsMentions[chat_id].mentions == 0) return
            }

            // console.log('observing??????')

            let root = document.querySelector('.comments_block');

            let elements = [];

            let mentions

            if (this.type == 'list' && project_id) {
                mentions = { ...this.getListsMentions[project_id][task_id] }
                delete mentions['mentions']
                mentions = Object.keys(mentions)
            }

            if (this.type == 'chat' && chat_id) {
                mentions = { ...this.getChatsMentions[chat_id].messages }
                // console.log(mentions, 'mentiond????')
                // delete mentions['mentions']
                mentions = Object.keys(mentions)
            }

            for (let id = 0; id < mentions.length; id++) {
                elements.push(document.getElementById(`comment__${mentions[id]}`))
            }

            // console.log(mentions, elements, 'mentions???????????????????????????????{{{{{}}}}}')

            let options = {
                root: root,
                rootMargin: "0px",
                threshold: 0.25
            };
            let observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    // console.log('windowActive: ', this.windowActive, 'isIntersecting: ', entry.isIntersecting)

                    if (!entry.isIntersecting || !this.windowActive) return;

                    if (this.type == 'list') {
                        // console.log('list remotinad')
                        this.removeMentionForList({ project_id, task_id, comment_id: entry.target.id.split('__')[1] })
                    } else if (this.type == 'chat') {
                        // console.log('chat remotinad')
                        this.removeMentionForChat({ chat_id, comment_id: entry.target.id.split('__')[1] })
                    }

                    // console.log('hello wordl? ', mentions)
                    observer.unobserve(entry.target)
                });
            }, options);

            elements.forEach(element => {
                if (!element) return
                observer.observe(element);
            });
        },
        window_resize() {

            const ownAvatars = this.type == 'chat' ? document.querySelectorAll('.own-comment-avatar-chat') : []

            if (document.querySelector('.chat')) {
                let comment_user = document.querySelectorAll('.chat .comment_user')

                if (document.querySelector('.chat .comment-message-wrapper') && document.querySelector('.chat .comment-message-wrapper').clientWidth > 700) {
                    comment_user.forEach((e, i) => {
                        e.classList.add('comment_guest')
                    })
                } else {
                    comment_user.forEach((e, i) => {
                        e.classList.remove('comment_guest')
                    })
                }
            }

            let comment_guest = document.querySelectorAll('.comment_guest')
            const allAvatars = this.type == 'chat' ? document.querySelectorAll('.avatar-container') : []
            const container = document.querySelector('.comment-message-wrapper')
            if (container && container.clientWidth < 560) {
                comment_guest.forEach((e, i) => {
                    e.classList.add('no_avatar')
                })
            } else {
                comment_guest.forEach((e, i) => {
                    e.classList.remove('no_avatar')
                })
                allAvatars.forEach(element => element.classList.remove('hidden'))
            }

            const allGuestAvatars = document.querySelectorAll('.avatar-container-guest')
            if (container && container.clientWidth > 700) {
                ownAvatars.forEach(element => element.classList.remove('hidden'))
            } else {
                ownAvatars.forEach(element => element.classList.add('hidden'))
            }

            if (container && container.clientWidth < 560) {
                allGuestAvatars.forEach(element => element.classList.add('hidden'))
            } else {
                allGuestAvatars.forEach(element => element.classList.remove('hidden'))
            }

        },
        isLastCommentInViewport() {
            const lastComment = Array.from(document.querySelectorAll(`.comment_${this.type}`)).pop()
            if (lastComment) {
                const rect = lastComment.getBoundingClientRect()
                return (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                )
            } else false
        },
        setObserver(id) {
            setTimeout(() => {

                let root = document.querySelector('.comments_' + this.type)
                let elements = [document.querySelector(`[data-id="comment__${id}"]`)]

                let options = {
                    root: root,
                    rootMargin: "0px",
                    threshold: 0.8
                }

                let observer = new IntersectionObserver((entries) => {
                    entries.forEach(entry => {
                        if (!entry.isIntersecting) return
                        this.unreadMessageCache.delete(id)
                        this.random = Math.random()
                        if (this.unreadMessageCache.size == 0) this.shouldShowGoToLastBtn = false
                        observer.unobserve(entry.target)
                    })
                }, options)

                elements.forEach(element => {
                    if (!element) return
                    observer.observe(element)
                })

            }, 500)
        },
        get_user(user_id) {
            if (this.getUserData._id == user_id) {
                return this.getUserData
            } else {
                if (this.getKnownUsers.find(user => user._id == user_id)) {
                    return this.getKnownUsers.find(user => user._id == user_id)
                } else {
                    return {
                        _id: 0,
                        name: 'Someone',
                        color: '#FF8686',
                        image: true
                    }
                }
            }
        },
        check_file_ext(comment) {
            if (comment.message == '') {
                if (comment.files[comment.files.length - 1].ext == 'png') {
                    return true
                } else if (comment.files[comment.files.length - 1].ext == 'jpg') {
                    return true
                } else if (comment.files[comment.files.length - 1].ext == 'jpeg') {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        },
        async get_file_url(file, msg) {

            let file_body = ''
            if (this.type == 'list') {
                file_body = this.getComments[this.$route.params.task_id].find(e => e._id == msg._id).files[0]
            } else {
                file_body = this.getChats.find(chat => chat._id == this.$route.params.chat_id).messages.find(e => e._id == msg._id).files[0]
            }

            const aws = new File()

            if (this.type == 'list') {
                if (file_body.imgWidth > 200 && file_body.size > 10000) {
                    return await aws.read('Lists' + '/' + this.$route.params.project_id + '/' + this.$route.params.task_id + '/' + '200' + '__' + file._id + '__' + file.filename)
                } else {
                    return await aws.read('Lists' + '/' + this.$route.params.project_id + '/' + this.$route.params.task_id + '/' + file._id + '__' + file.filename)
                }
            } else {
                if (file_body.imgWidth > 200 && file_body.size > 10000) {
                    return await aws.read('Chats' + '/' + this.$route.params.chat_id + '/' + '200' + '__' + file._id + '__' + file.filename)
                } else {
                    return await aws.read('Chats' + '/' + this.$route.params.chat_id + '/' + file._id + '__' + file.filename)
                }
            }

        },
        go_to_comment(comment_id) {
            if (comment_id) {

                if (this.type == 'chat') {
                    let index = this.getChats.find(chat => chat._id == this.$route.params.chat_id).messages.reverse().findIndex(e => e._id == comment_id)
                    this.getChats.find(chat => chat._id == this.$route.params.chat_id).messages.reverse()
                    this.lazy_load_num = index + 10
                    this.comments = []
                    this.comments_render()
                }

                setTimeout(() => {
                    const comment = document.querySelector(`[data-id='comment__${comment_id}']`)

                    if (comment) {

                        comment.querySelector(".comment_text").classList.add("hover")

                        setTimeout(() => {
                            document.querySelector(`[data-id='comment__${comment_id}']`).scrollIntoView({ behavior: "smooth", block: "center" })
                            this.setJumpToMessage(null)
                            this.toScroll = true
                        }, 10)

                        setTimeout(() => {
                            comment.querySelector(".comment_text").classList.remove("hover")
                        }, 3000)

                    } else {
                        this.setJumpToMessage(null)
                        this.setMessage({
                            key_message: "warning",
                            message: "Sorry, there is no such message."
                        })
                    }
                }, 500)

            }
        },
        startDownPress(comment, e) {
            this.interval_ended = false
            this.time = 0
            let interval = setInterval(() => {
                if (this.interval_ended) {
                    clearInterval(interval)
                }
                else if (this.time >= 400) {

                    const body = document.querySelector('.comments')
                    document.querySelector('.content').classList.add('filter-blur')

                    const element = document.querySelector(`[data-id="comment__${comment._id}"]`)
                    const {top} = element.getBoundingClientRect()
                    const {width, height} = document.querySelector(`[data-id="comment__${comment._id}"] .comment_text`).getBoundingClientRect()
                    
                    document.querySelector('.duplicate').innerHTML = ''
                    document.querySelector('.duplicate').style = ''
                    
                    document.querySelector('.duplicate').innerHTML = document.querySelector(`[data-id="comment__${comment._id}"`).outerHTML
                    document.querySelector('.duplicate').style.top = top + 'px'

                    if (document.querySelector(`[data-id="comment__${comment._id}"`).classList.contains('comment_user')) {
                        document.querySelector('.duplicate').style.right = 10 + 'px'
                        document.querySelector('.duplicate .comment_text').style.width = width + 'px'
                        // document.querySelector('.duplicate .comment_text').style.height = height + 'px'
                    } else {
                        document.querySelector('.duplicate').style.left = 5 + 'px'
                        document.querySelector('.duplicate .comment_text').style.width = width + 16 + 'px'
                        // document.querySelector('.duplicate .comment_text').style.height = height + 'px'
                    }
                    
                    if (this.$refs.comments_block.nextElementSibling) {
                        this.$root.$emit('selectMessage', {
                            comment,
                            position: {
                                mouseX: e.targetTouches[0].clientX,
                                mouseY: e.targetTouches[0].clientY,
                                chatWindow: {
                                    top: body.clientHeight - this.$refs.comments_block.nextElementSibling.clientHeight - this.$refs.comments_block.clientHeight,
                                    right: body.clientWidth,
                                    left: body.clientWidth - this.$refs.comments_block.clientWidth,
                                    bottom: body.clientHeight - this.$refs.comments_block.nextElementSibling.clientHeight,
                                    height: this.$refs.comments_block.clientHeight,
                                    width: this.$refs.comments_block.clientWidth
                                },
                                window: {
                                    height: body.clientHeight,
                                    width: body.clientWidth
                                }
                            }
                        })
                    }
                    else {
                        this.$root.$emit('selectMessage', {
                            comment,
                            position: {
                                mouseX: e.targetTouches[0].clientX,
                                mouseY: e.targetTouches[0].clientY,
                                chatWindow: {
                                    top: body.clientHeight - this.$refs.comments_block.clientHeight,
                                    right: body.clientWidth,
                                    left: body.clientWidth - this.$refs.comments_block.clientWidth,
                                    bottom: body.clientHeight,
                                    height: this.$refs.comments_block.clientHeight,
                                    width: this.$refs.comments_block.clientWidth
                                },
                                window: {
                                    height: body.clientHeight,
                                    width: body.clientWidth
                                }
                            }
                        })
                    }

                    this.menu_opened = true
                    this.interval_ended = true

                    clearInterval(interval);
                }
                this.time += 400;
            }, 400);

            // console.log('down')

        },
        upPress() {
            // console.log('upPress')
            if (!this.menu_opened) {
                this.interval_ended = true;
            }
        },
        menu_actions(comment, e) {

            const body = document.querySelector('.comments')
            setTimeout(() => {
                this.$root.$emit('selectMessage', {
                    comment,
                    position: {
                        mouseX: e.clientX,
                        mouseY: e.clientY,
                        chatWindow: {
                            top: body.clientHeight - this.$refs.comments_block.nextElementSibling.clientHeight - this.$refs.comments_block.clientHeight,
                            right: body.clientWidth,
                            left: body.clientWidth - this.$refs.comments_block.clientWidth,
                            bottom: body.clientHeight - this.$refs.comments_block.nextElementSibling.clientHeight,
                            height: this.$refs.comments_block.clientHeight,
                            width: this.$refs.comments_block.clientWidth
                        },
                        window: {
                            height: body.clientHeight,
                            width: body.clientWidth
                        }
                    }
                })
            }, 100)

        },
        scroll_bottom() {

            this.unreadMessageCache.clear()
            this.shouldShowGoToLastBtn = false

            setTimeout(() => {
                const comments_block = this.$refs.comments_block
                if (comments_block) {
                    comments_block.scrollTop = this.$refs.messages ? this.$refs.messages.scrollHeight : comments_block.scrollHeight
                }
            }, 5)

        },
        created() {

            if (!this.author && !this.date) {
                return
            }

            let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

            let date = new Date(Number(this.date))

            let task_created = date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear()
            let task_created_time = this.$w_time_parse(date, this.getUserData.date_format.time)

            return `Created by ${this.get_user(this.author).name} on ${task_created} at ${task_created_time}`

        },
        scroll_date_sticky(e) {

            const dates = document.querySelectorAll(".date-comment-wrapper")
            dates.forEach((date, i) => {
                var viewportOffset = date.getBoundingClientRect()
                if (i != 0) {
                    var viewportOffset2 = dates[i - 1].getBoundingClientRect()
                    if (viewportOffset.top == viewportOffset2.top) {
                        dates[i - 1].classList.add('no-sticky')
                    } else {
                        dates[i - 1].classList.remove('no-sticky')
                    }
                }
            })

            if (this.type == 'chat') {
                if (e.target.scrollTop == 0) {
                    if (this.lazy_load_num <= this.getChats.find(chat => chat._id == this.$route.params.chat_id).messages.length) {
                        this.lazy_load_loader = true
                        let chat = this.getChats.find(chat => chat._id == this.$route.params.chat_id)
                        let messages = chat.messages.slice(-this.lazy_load_num)
                        this.lazy_load_num = this.lazy_load_num + 70
                        this.comments_render(this.chat_comments(this.lazy_load_num))
                        setTimeout(() => {
                            document.querySelector(`[data-id='comment__${messages[0]._id}']`).scrollIntoView(true)
                            document.querySelector('.comments_block').scrollTop = e.target.scrollTop - 133
                            this.toScroll = true
                        }, 0)
                    }
                }
            }

        },
        is_file(comment) {

            let exts = ['png', 'jpg', 'jpeg', 'web']

            if (comment.files_body) {
                if (comment.files_body.length > 0) {
                    let s = exts.filter(e => e != comment.files_body[0].ext)
                    if (s.length == 4) {
                        return 'comment_text_file comment_text'
                    } else {
                        return 'comment_text_img comment_text'
                    }
                } else if (comment.files) {
                    if (comment.files.length > 0) {
                        let s = exts.filter(e => e != comment.files[0].ext)
                        if (s.length == 4) {
                            return 'comment_text_file comment_text'
                        } else {
                            return 'comment_text_img comment_text'
                        }
                    } else {
                        return 'comment_text'
                    }
                } else {
                    return 'comment_text'
                }
            } else if (comment.files) {
                if (comment.files.length > 0) {
                    let s = exts.filter(e => e != comment.files[0].ext)
                    if (s.length == 4) {
                        return 'comment_text_file comment_text'
                    } else {
                        return 'comment_text_img comment_text'
                    }
                } else {
                    return 'comment_text'
                }
            } else {
                return 'comment_text'
            }

        },
        get_comments() {
            // alert(this.$store.state.comments.comments[this.$route.params.task_id])
            if (this.type == 'list') {
                this.comments = this.$store.state.comments.comments[this.$route.params.task_id] || []
            } else if (this.type == 'chat') {
                this.comments = this.chat_comments()
            }
        },
        chat_comments(start = this.lazy_load_num) {
            if (this.getChats.find(chat => chat._id == this.$route.params.chat_id)) {
                let chat = this.getChats.find(chat => chat._id == this.$route.params.chat_id)
                return chat.messages.slice(-start)
            }
        },
        restructureComments(allComments) {
            let comments = []
            let previousUser = allComments[0] ? allComments[0].user_id : ''
            let currentUser = allComments[0] ? allComments[0].user_id : ''
            let temp = []
            let i = 1

            allComments.forEach(comment => {

                if (comment.date_for_chat) {
                    comments.push({ values: temp, id: i++ })
                    comments.push({ values: [comment], id: i++ })
                    previousUser = ''
                    temp = []
                    return
                }

                currentUser = comment.user_id
                // temp.push(comment)
                // console.log(temp, 'telml===========================')
                if (currentUser != previousUser) {
                    comments.push({ values: temp, id: i++ })
                    temp = [comment]
                } else {
                    temp.push(comment)
                }
                previousUser = currentUser
            })
            if (temp.length) {
                comments.push({ values: temp, id: i++ })
            }
            return comments
        },
        renderReplyImage() {
            let comments = []
            if (this.type == 'list') {
                comments = this.$store.state.comments.comments[this.$route.params.task_id] || []
            } else if (this.type == 'chat') {
                comments = this.chat_comments()
            }
            comments.forEach(async (comment) => {
                if (comment.reply) {
                    if (comment.reply.files.length > 0) {
                        let url = await this.get_file_url(comment.reply.files[0], comment.reply)
                        this.replyImage.push({
                            _id: comment.reply._id,
                            url: url
                        })
                    }
                }
            })
        },
        comments_render(messages) {

            if (this.comments.length == 0) {
                this.get_comments()
                messages = this.comments
            }

            if (messages) {
                let promise = new Promise((resolve, reject) => {
                    if (messages.length > 0) {
                        let comments = []
                        messages.forEach((e, i) => {
                            if (i == 0) {
                                comments.push(e)
                            } else {
                                if (e._id != messages[i - 1]._id) {
                                    comments.push(e)
                                }
                            }
                        })
                        comments = this.comments_date(comments)
                        comments = this.comments_class(comments)
                        comments = this.comments_style(comments)
                        comments = this.comments_send(comments)
                        comments = this.restructureComments(comments)
                        this.comments = comments
                    }
                    resolve(1)
                })

                promise.then(() => {
                    this.window_resize()
                    this.lazy_load_loader = false
                    this.renderReplyImage()
                })

            }
            setTimeout(() => {
                this.setObserverForMentions()
            }, 200)

        },
        comments_date(comments) {

            let messages = []

            comments.forEach((e, i) => {
                if (i == 0) {
                    let a = this.$w_date_parse(e.date)
                    a = a.split(' ')
                    if (a.length == 1) {
                        a = a[0]
                    } else {
                        a = a[2] ? a[1] + ' ' + a[0] + ', ' + a[2] : a[1] + ' ' + a[0]
                    }
                    messages.push({
                        date_for_chat: a
                    })
                    messages.push(e)
                } else {
                    if (this.$w_date_parse(e.date) == this.$w_date_parse(comments[i - 1].date)) {
                        messages.push(e)
                    } else {
                        let a = this.$w_date_parse(e.date)
                        a = a.split(' ')
                        if (a.length == 1) {
                            a = a[0]
                        } else {
                            a = a[2] ? a[1] + ' ' + a[0] + ', ' + a[2] : a[1] + ' ' + a[0]
                        }
                        messages.push({
                            date_for_chat: a
                        })
                        messages.push(e)
                    }
                }
            })

            return messages

        },
        comments_style(comments) {

            comments.forEach((comment, i) => {
                if (comment._id && document.querySelector(`.comment[data-id="comment__${comment._id}"] .comment_body`)) {

                    let comment_body = document.querySelector(`.comment[data-id="comment__${comment._id}"] .comment_body`).offsetWidth

                    if (document.querySelector(`.comment[data-id="comment__${comment._id}"] .comment_body .comment_text__body__span`)) {

                        let $w_comment_body__span = document.querySelector(`.comment[data-id="comment__${comment._id}"] .comment_body .comment_text__body__span`).offsetWidth
                        let $h_comment_body__span = document.querySelector(`.comment[data-id="comment__${comment._id}"] .comment_body .comment_text__body__span`).offsetHeight

                        if ($h_comment_body__span == 17 || $h_comment_body__span == 13) {
                            if (comment_body - 22 <= $w_comment_body__span + 35) {
                                comment.style = 'margin-top: 0px'
                            } else {
                                if (comment.remove_file) {
                                    comment.style = 'margin-top: 0px; font-style: italic'
                                } else {
                                    comment.style = 'margin-top: 0px; z-index: 1'
                                }
                            }
                        } else {
                            if (comment.remove_file) {
                                comment.style = 'margin-top: 0px; font-style: italic'
                            } else {
                                comment.style = 'margin-top: 0px; z-index: 2'
                            }
                        }

                    }

                }
            })

            return comments

        },
        comments_class(comments) {

            comments.forEach((e, i) => {

                comments[i].class = ''

                if (comments.length > (i + 1)) {
                    if (comments[i + 1].user_id) {

                        if (e.date_for_chat && i != 0) {
                            comments[i - 1].class += 'last_chat '
                        }

                        if (e.user_id == comments[i + 1].user_id) {
                            if (comments[i + 1].date_for_chat) {
                                comments[i].class += 'last_chat '
                            } else {
                                comments[i].class += ''
                            }
                        } else {
                            comments[i].class += 'last_chat '
                        }

                    }
                }

                if (e.user_id == this.getUserData._id) {
                    comments[i].class += 'comment comment_user '
                } else {
                    comments[i].class += 'comment comment_guest '
                }

                if (!e.users) {
                    comments[i].users = []
                }

            })

            if (comments.length > 0) {
                comments[comments.length - 1].class += 'last_chat'
            }

            return comments

        },
        comments_send(comments) {

            this.sendMessages.forEach((msg) => {
                comments.forEach((comment, i) => {
                    if (msg == comment._id) {
                        comments[i].send = false
                    }
                })
                setTimeout(() => {
                    this.scroll_bottom()
                }, 10)
            })

            this.errorSendMessages.forEach((msg) => {
                comments.forEach((comment, i) => {
                    if (msg._id == comment._id) {
                        comments[i].error = true
                    }
                })
            })

            return comments

        },
        comment_parse(message) {
            let mentions = {}
            let messageArr = message.replace(/\n\r?/g, ' ').split(' ')
            for (let i = 0; i < messageArr.length; i++) {
                if (messageArr[i] in this.usersByEmail) {
                    mentions[messageArr[i]] = this.usersByEmail[messageArr[i]]
                }
            }

            let urlRegex = /(http(s)?:\/\/[^\s]+)/g

            message = message.replace(/</g, '&lt;')
            message = message.replace(/>/g, '&gt;')

            for (let email in mentions) {
                message = message.replaceAll(email, `<span class='tagged-user'>${mentions[email]}</span>`)
            }

            return message.replace(urlRegex, function (url) {
                return '<a href="' + url + '">' + url + '</a>'
            })

        },
        commentParseMentions(message) {
            let mentions = {}
            let messageArr = message.split(' ')
            for (let i = 0; i < messageArr.length; i++) {
                if (messageArr[i] in this.usersByEmail) {
                    mentions[messageArr[i]] = this.usersByEmail[messageArr[i]]
                }
            }

            let urlRegex = /(http(s)?:\/\/[^\s]+)/g

            message = message.replace(/</g, '&lt;')
            message = message.replace(/>/g, '&gt;')

            for (let email in mentions) {
                message = message.replaceAll(email, mentions[email])
            }

            return message

        },
        comment_read() {
            if (this.sendMessagesBool && !this.getIsAppInForeground) {
                this.sendMessagesBool = false
                setTimeout(() => {
                    this.sendMessagesBool = true
                }, 1000)
                if (this.getApplicationStatus == 'updating') return
                if (this.type == 'list') {

                    let number_unread = ''

                    if (this.getTasks[this.$route.params.project_id]) {
                        number_unread = this.getTasks[this.$route.params.project_id].filter(e => e._id == this.$route.params.task_id)
                    }

                    if (number_unread.length == 0) {
                        if (this.getCompletedTasks[this.$route.params.project_id]) {
                            number_unread = this.getCompletedTasks[this.$route.params.project_id].filter(e => e._id == this.$route.params.task_id)
                        }
                    }

                    let getComments = this.getComments[this.$route.params.task_id]
                    let comment = getComments ? getComments[getComments.length - 1] : ''

                    setTimeout(() => {
                        if ((number_unread[0] && number_unread[0].unread_message) || (number_unread[0] && number_unread[0].real_unread_message)) {
                            if (number_unread[0].unread_message != 0) {
                                this.socket_read_message({
                                    task_id: this.$route.params.task_id,
                                    user_id: this.getUserData._id,
                                    all_read: true,
                                    project_id: this.$route.params.project_id,
                                    chat_id: comment ? comment._id : ''
                                })
                            }
                        }
                    }, 1000)

                } else {

                    let bool = false

                    this.getChats.forEach((chat) => {
                        if (chat._id == this.$route.params.chat_id) {

                            chat.users.forEach((user) => {
                                if (user.user_id == this.getUserData._id) {
                                    if (user.unread_number > 0) {
                                        bool = true
                                    }
                                }
                            })

                        }
                    })

                    setTimeout(() => {
                        if (bool) {
                            bool = false
                            let values = this.comments[this.comments.length - 1].values
                            let last_msg_id = this.comments[this.comments.length - 1].values[values.length - 1]._id
                            this.$socket.emit('IN_ReadMessages', this.$route.params.chat_id, last_msg_id)
                        }
                    }, 1000)

                }
            }
        },
        router_open_link(e) {
            e.preventDefault()
            const link = e.target.closest("a")
            if (link) {
                if (link.origin == window.location.origin) {
                    let url = link.href.replace(link.origin, '')
                    this.$router.push(url).catch(() => { })
                } else {
                    window.open(link.href, '_blank')
                }
            }
        }
    }
}

</script>
