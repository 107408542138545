<template>
  <div class="files_block attachment" id="files_block" ref="files_block">
    <div v-if="files.length > 0" class="files_block__file attachment">
      <div
        v-for="file in files"
        :key="file._id"
        :id="`attachment_${file._id}`"
        :ref="`attachment_${file._id}`"
        class="files_block__file_body attachment_body"
      >
        <!-- <DocFile
          :file_body="file_body"
          :src="'attachment'"
          :date="file_body.date"
          :user_name="getUserData.name"
        /> -->
        <DocFile
          :setEncodedImage="setEncodedImage(file)"
          :type="'list'"
          :src="'attachment'"
          :file="file"
          :user_name="get_user(get_comment(file.comment_id).user_id).name"
          :comment="get_comment(file.comment_id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DocFile from "@/components/comment/DocFile"
import {EventEmitter} from '../../main'

export default {
  name: "File",
  data() {
    return {
      files: []
    }
  },
  computed: {
    ...mapGetters(["get_files", "get_flag_for_files", "getUserData", "getComments", "getKnownUsers"]),
    comments() {
      return this.$store.state.comments.comments
    }
  },
  mounted() {
    EventEmitter.$on('Files', () => {
      this.setFiles()
    })
    setTimeout(() => {
      this.setFiles()
    }, 100)
  },
  methods: {
    get_user(user_id) {
      if (this.getUserData._id == user_id) {
        return this.getUserData
      } else {
        return this.getKnownUsers.find(user => user._id == user_id)
      }
    },
    get_comment(id) {
      if (this.comments[this.$route.params.task_id]) {
        return this.comments[this.$route.params.task_id].find(e => e._id == id)
      }
    },
    setEncodedImage(img) {
      return function(base64) {
        img.base64 = base64
      }
    },
    setFiles() {
      this.files = []
      if (this.comments[this.$route.params.task_id]) {
        let comments = this.comments[this.$route.params.task_id]
        comments = comments.filter(e => e.files.length > 0)
        comments.forEach(e => {
          e.files.forEach(f => {
            f.comment_id = e._id
            this.files.push(f)
          })
        })
      }
    }
  },
  watch: {
    comments() {
      console.log(1)
      this.setFiles()
    },
    get_flag_for_files(e) {
      this.setFiles()
    }
  },
  components: {
    DocFile
  }
}
</script>
