<template>

    <div
        v-if="file.ext.toLowerCase().match(/png|jpg|jpeg|gif/) && src == 'chat'" 
        class="comment_text__file_img attachment__file_img" 
        :data-file-ext="file.ext"
        :ref="`attachment_body_${file._id}`"
    >

        <img v-if="file.base64" @load="onLoad" :class="{hide: !imgSource.loaded}" class="big_img_file" :src="file.base64" :alt="file.filename" @click="$root.$emit('showLightBox', file._id, comment._id, type == 'chat' ? $route.params.chat_id : undefined, type)"/>
        <img v-else @load="onLoad" :class="{hide: !imgSource.loaded && !precent_loader}" class="big_img_file" :src="url" :alt="file.filename" @click="$root.$emit('showLightBox', file._id, comment._id, type == 'chat' ? $route.params.chat_id : undefined, type)"/>

        <div v-if="typeof precent_loader == 'number'" class="comment_text__file_img__loading attachment__file_img__loading">
            <el-progress type="circle" :percentage="+precent_loader.toFixed()" color="#447692">
                {{ +precent_loader.toFixed() }}%
            </el-progress>
        </div>

        <div v-if="!imgSource.loaded && !precent_loader" class="spinner-holder" :style="{minHeight: file.imgHeight ? file.imgHeight + 'px' : ''}">
            <div>
                <span class="image-pre-loader"></span>
            </div>
        </div>
        
    </div>

    <div
        v-else-if="file.ext.toLowerCase().match(/png|jpg|jpeg|gif/) || src == 'chat' || src != 'chat' || !file.ext.toLowerCase().match(/png|jpg|jpeg|gif/)"
        class="comment_text__file_doc attachment_doc"
        :class="src == 'chat' ? 'in_chat' : ''"
        :ref="`attachment_body_${file._id}`"
        :data-file-ext="file.ext"
    >

        <a 
            href="#"
            @click="download_file(url, file.filename, file._id)"
            class="comment_text__file_doc__thumbnail attachment_doc__thumbnail"
            :class="typeof file.loader == 'number' ? 'loading' : ''"
        >
            <span class="comment_text__file_doc__thumbnail__name attachment_doc__thumbnail__name" v-if="download_done">
                {{ file.ext.toLowerCase() == 'docx' ? 'doc' : file.ext }}
            </span>
            <span class="comment_text__file_doc__thumbnail__name attachment_doc__thumbnail__name" v-else>
                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7 0.500003C7.24864 0.500003 7.4871 0.598774 7.66292 0.77459C7.83873 0.950405 7.9375 1.18886 7.9375 1.4375L7.9375 12.2994L11.9613 8.27375C12.0484 8.18659 12.1519 8.11744 12.2658 8.07027C12.3797 8.0231 12.5017 7.99882 12.625 7.99882C12.7483 7.99882 12.8703 8.0231 12.9842 8.07027C13.0981 8.11744 13.2016 8.18659 13.2888 8.27375C13.3759 8.36092 13.4451 8.4644 13.4922 8.57828C13.5394 8.69217 13.5637 8.81423 13.5637 8.9375C13.5637 9.06077 13.5394 9.18284 13.4922 9.29672C13.4451 9.41061 13.3759 9.51409 13.2888 9.60125L7.66375 15.2263C7.57667 15.3136 7.47321 15.3828 7.35931 15.4301C7.24542 15.4774 7.12332 15.5017 7 15.5017C6.87669 15.5017 6.75459 15.4774 6.64069 15.4301C6.52679 15.3828 6.42334 15.3136 6.33625 15.2263L0.711252 9.60125C0.535214 9.42522 0.436317 9.18646 0.436317 8.9375C0.436317 8.68855 0.535214 8.44979 0.711252 8.27375C0.887289 8.09771 1.12605 7.99882 1.375 7.99882C1.62396 7.99882 1.86271 8.09771 2.03875 8.27375L6.0625 12.2994L6.0625 1.4375C6.0625 1.18886 6.16127 0.950405 6.33709 0.77459C6.51291 0.598774 6.75136 0.500003 7 0.500003Z" fill="white"/>
                </svg>
            </span>

            <div v-if="!new_file" class="this_user">

                <el-progress
                    class="comment__attachment__load"
                    v-if="precent_loader"
                    type="circle"
                    :percentage="+precent_loader.toFixed()"
                    :color="progressColor()"
                ></el-progress>

                <span class="comment__attachment__load__cancel" v-if="precent_loader" @click="stop_download(file._id, $event)">
                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.4475 6.44623L11.4475 11.4462M1.4475 11.4462L6.4475 6.44623L1.4475 11.4462ZM11.4475 1.44623L6.44655 6.44623L11.4475 1.44623ZM6.44655 6.44623L1.4475 1.44623L6.44655 6.44623Z" stroke="white" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </span>

            </div>

            <div v-else class="guest_user newFile">

                <el-progress
                    class="comment__attachment__load"
                    v-if="precent_loader"
                    type="circle"
                    :percentage="+precent_loader.toFixed()"
                    color="#3C7916"
                ></el-progress>

                <span class="comment__attachment__load__cancel" v-if="precent_loader" @click="delete_comment(comment, $event)">
                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.4475 6.44623L11.4475 11.4462M1.4475 11.4462L6.4475 6.44623L1.4475 11.4462ZM11.4475 1.44623L6.44655 6.44623L11.4475 1.44623ZM6.44655 6.44623L1.4475 1.44623L6.44655 6.44623Z" stroke="white" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </span>

            </div>

        </a>

        <div class="comment_text__file_doc__body attachment_doc__body">
            <div class="comment_text__file_doc__name attachment_doc__name">
                <span class="attachment__name">
                    {{ file.filename.replace(file.ext, '').replace(file.filename.replace(file.ext, '').substr(-3), '') }}
                </span>
                <p>
                    {{ file.filename.replace(file.ext, '').substr(-3)[file.filename.replace(file.ext, '').substr(-3).length - 1] == '.' ?file.filename.replace(file.ext, '').substr(-3) + file.ext : file.filename.replace(file.ext, '').substr(-3) + '.' + file.ext }}
                </p>
            </div>
            <div v-if="precent_loader" class="comment_text__file_doc__size">
                {{ $w_parse_doc_size(+precent_loader_num) + ' / ' + $w_parse_doc_size(+file.size) }}
            </div>
            <div v-else class="comment_text__file_doc__size">
                {{ $w_parse_doc_size(+file.size) }}
            </div>
            <div v-if="src != 'chat'" class="comment_text__file_doc__sended">
                {{ createDate(comment.date ? comment.date : new Date()) }} at {{ $w_time_parse(comment.date ? comment.date : new Date(), getUserData.date_format.time) }} by {{ user_name }}
            </div>
        </div>

        <a
            v-if="src != 'chat'"
            href="#"
            @click="download_file(url, file.filename, file._id)"
            class="attachment_download"
        >
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 0C6.71575 0 0 6.71575 0 15C0 23.2843 6.71575 30 15 30C23.2842 30 30 23.2842 30 15C30 6.71575 23.2842 0 15 0ZM15 26.7391C8.51575 26.7391 3.26087 21.4826 3.26087 15C3.26087 8.5174 8.51575 3.26087 15 3.26087C21.4842 3.26087 26.7391 8.5174 26.7391 15C26.7391 21.4826 21.4842 26.7391 15 26.7391ZM17.9348 8.72283H12.0652V15.3913H8.39675L15 21.6033L21.6033 15.3913H17.9348L17.9348 8.72283Z" fill="#6CB2CE"/>
            </svg>
        </a>

    </div>

</template>

<script>

    import { mapMutations, mapActions, mapGetters } from "vuex"
    import ErrorReq from "./ErrorReq"
    import {EventEmitter} from '../../main'
    import File from '../../utils/aws.js'

    export default {
        name: "DocFile",
        props: {
            setEncodedImage: {
                type: Function,
                default: () => {}
            },
            type: String,
            src: String,
            user_name: String,
            comment: Object,
            file: [Object, File],
        },
        data() {
            return {
                precent_loader: null,
                precent_loader_num: null,
                error_req: false,
                url: '',
                download_done: false,
                new_file: false,
                imgSource: {}
            }
        },
        mounted() {

            this.imgSource = {
                url: this.file.url,
                loaded: this.file.base64 ? true : false
            }

            this.project_id = this.$route.params.project_id

            setTimeout(async () => {

                if (this.type == 'chat') {

                    if (this.file.ext.toLowerCase() == 'png' || this.file.ext.toLowerCase() == 'jpg' || this.file.ext.toLowerCase() == 'jpeg') {
                        if (this.file.imgWidth > 200 && this.file.size > 10000) {
                            const file = new File()
                            this.url = await file.read('Chats' + '/' + this.$route.params.chat_id + '/' + '200' + '__' + this.file._id + '__' + this.file.filename)
                        } else {
                            const file = new File()
                            this.url = await file.read('Chats' + '/' + this.$route.params.chat_id + '/' + this.file._id + '__' + this.file.filename)
                        }
                    } else {
                        const file = new File()
                        this.url = await file.read('Chats' + '/' + this.$route.params.chat_id + '/' + this.file._id + '__' + this.file.filename)
                    }

                } else {
        
                    if (this.file.ext.toLowerCase() == 'png' || this.file.ext.toLowerCase() == 'jpg' || this.file.ext.toLowerCase() == 'jpeg') {
                        const file = new File()
                        if (this.file.imgWidth > 200 && this.file.size > 10000) {
                            this.url = await file.read('Lists' + '/' + this.project_id + '/' + this.$route.params.task_id + '/' + '200' + '__' + this.file._id + '__' + this.file.filename)
                        } else {
                            this.url = await file.read('Lists' + '/' + this.project_id + '/' + this.$route.params.task_id + '/' + this.file._id + '__' + this.file.filename)
                        }
                    } else {
                        const file = new File()
                        this.url = await file.read('Lists' + '/' + this.project_id + '/' + this.$route.params.task_id + '/' + this.file._id + '__' + this.file.filename)
                    }

                }
                if (!this.file.base64) {
                    if (this.file.ext.toLowerCase() == 'png' || this.file.ext.toLowerCase() == 'jpg' || this.file.ext.toLowerCase() == 'jpeg') {
                        this.urlToBase64(this.url)
                    }
                }
            }, 500)

            this.$root.$on("file_onload", (data) => {
                if (data[this.file._id] != undefined) {
                    if (typeof data[this.file._id].precent == "number" && data[this.file._id].precent <= 100) {
                        this.precent_loader = data[this.file._id].precent
                        this.file.loader = data[this.file._id].precent
                        this.precent_loader_num = data[this.file._id].loaded
                        this.new_file = data[this.file._id].new_file
                    } else {
                        this.precent_loader = null
                        this.file.loader = null
                        this.precent_loader_num = null
                        this.new_file = false
                    }
                }
            })

            EventEmitter.$on("file_onload_num", (data) => {
                if (data) {
                    this.precent_loader = Number(data)
                }
            })

            this.$root.$on("error_req_attachment", (data) => {
                this.error_req = data[this.file._id]
            })

            setTimeout(() => {
                this.download()
            }, 10)

        },
        methods: {
            ...mapActions([
                "socket_delete_message", 
                "socket_repead_send"
            ]),
            ...mapMutations([
                "setUserFileSize_minus"
            ]),
            urlToBase64(url, name) {
                var xhr = new XMLHttpRequest();
                xhr.onload = () => {
                    var reader = new FileReader();
                    reader.onloadend = () => {
                        const res = reader.result
                        this.setEncodedImage(res)
                    }
                    reader.readAsDataURL(xhr.response);
                };
                xhr.open('GET', url);
                xhr.responseType = 'blob';
                xhr.send();
            },
            onLoad(e) {
                this.imgSource.loaded = true
            },
            progressColor(){
                if (this.comment.user_id == this.getUserData._id) {
                    if (this.src == 'chat') {
                        return '#3C7916'
                    } else {
                        return '#0298D3'
                    }
                } else {
                    return '#0298D3'
                }
            },
            stop_download(file_id, e) {

                e.stopPropagation()

                if (this.src == 'chat') {
                    if (this.comment.user_id == this.getUserData._id) {
                        this.download_done = true
                    } else {
                        this.download_done = false
                    }
                }
                
                localStorage.setItem(`stop_${file_id}`, true)

            },
            download_file(url, name, file_id) {

                let a = ''

                if (this.type == 'chat') {
                    if (this.file.ext.toLowerCase() == 'png' || this.file.ext.toLowerCase() == 'jpg' || this.file.ext.toLowerCase() == 'jpeg') {
                        if (this.file.imgWidth > 200) {
                            a = 'Chats' + '/' + this.$route.params.chat_id + '/' + 'full' + '__' + this.file._id + '__' + this.file.filename
                        } else {
                            a = 'Chats' + '/' + this.$route.params.chat_id + '/' + this.file._id + '__' + this.file.filename
                        }
                    } else {
                        a = 'Chats' + '/' + this.$route.params.chat_id + '/' + this.file._id + '__' + this.file.filename
                    }
                } else {
                    if (this.file.ext.toLowerCase() == 'png' || this.file.ext.toLowerCase() == 'jpg' || this.file.ext.toLowerCase() == 'jpeg') {
                        if (this.file.imgWidth > 200) {
                            a = 'Lists' + '/' + this.project_id + '/' + this.$route.params.task_id + '/' + 'full' + '__' + this.file._id + '__' + this.file.filename
                        } else {
                            a = 'Lists' + '/' + this.project_id + '/' + this.$route.params.task_id + '/' + this.file._id + '__' + this.file.filename
                        }
                    } else {
                        a = 'Lists' + '/' + this.project_id + '/' + this.$route.params.task_id + '/' + this.file._id + '__' + this.file.filename
                    }
                }

                this.$w_downloadFile(this, this.axios, url, name, file_id, a)

                setTimeout(() => {
                    this.download_done = true
                }, 1000)

            },
            delete_comment(comment, e) {

                e.stopPropagation()

                EventEmitter.$emit('StopFileSend', true)
                EventEmitter.$emit('delete_comment_in_proccess', comment._id)

                this.socket_delete_message({
                    task_id: comment.task_id,
                    chat_id: comment._id,
                    user_id: this.getUserData._id,
                    project_id: this.project_id,
                    comment
                })

            },
            download() {
                if (this.src == 'chat') {
                    if (!this.download_done) {
                        if (this.comment.user_id == this.getUserData._id) {
                            this.download_done = true
                        } else {
                            this.download_done = false
                        }
                    } else {
                        this.download_done = true
                    }
                } else {
                    this.download_done = true
                }
            },
            createDate(e) {
                const fullDate = this.$w_date_parse(e)

                let date = fullDate.split(' ')

                if (this.getUserData.date_format.date) {
                    if (this.getUserData.date_format.date == 'DD/MM/YYYY') {
                        return fullDate
                    }
                    if (this.getUserData.date_format.date == 'MM/DD/YYYY') {
                        if (date[2]) {
                            return date[1] + ' ' + date[0] + ' ' + date[2]
                        }
                        if (date[0]) {
                            return date[1] + ' ' + date[0]
                        }
                    }
                }
            }
        },
        components: {
            ErrorReq
        },
        computed: {
            ...mapGetters(["getUserData"])
        },
        watch: {
            url() {
                if (this.precent_loader || this.file.base64) {
                    this.imgSource = {
                        url: this.file.url,
                        loaded: true
                    }
                } else {
                    this.imgSource = {
                        url: this.file.url,
                        loaded: false
                    }
                }
            },
            precent_loader(newVal) {
                if (newVal) {
                    this.imgSource.loaded = true
                }
            }
        }
    }

</script>
