<template>
  <div v-if="true" class="task-rename" id="task-name-holder">
    <div class="task-rename__wrapper">
      <!-- v-if="editing" -->
      <textarea
        :style="{ overflow: editing ? 'scroll' : 'hidden' }"
        class="task-rename__textarea"
        id="taskEditing"
        v-model="taskName"
        spellcheck="false"
        ref="taskNameTextarea"
        maxlength="160"
        @input="(e) => update(e.target.value)"
        @scroll="sync_scroll"
        @click="handleClick"
        @blur="renameTask"
        @mousemove="handleMouseOver"
        @keypress.exact.enter="renameTask"
      ></textarea>
      <pre
        :style="{ overflow: editing ? 'scroll' : 'hidden' }"
        class="task-rename__pre"
        ref="highlightingElement"
        @click="handleClick"
        @mousemove="handleMouseOver"
        id="taskNameHighlighting"
      ><div 
        class="task-rename__content"
        id="highlightingContentTaskName"
        ref="taskNameHighlightingContent"
        v-html="urlify(taskName)"></div>
      </pre>
      <span
        v-if="shouldShowMoreOption"
        @click="toggleExtend"
        class="task-rename__more"
        >{{ moreText }}</span
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { EventEmitter } from "../../main";

export default {
  name: "Notes",
  props: {
    task: {
      type: Object,
      default: () => ({}),
    },
    project_id: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      editing: false,
      noteContent: "",
      taskName: "",
      prevTaskName: "",
      showPlaceholder: true,
      noteEmpty: false,
      prevContent: "",
      moreText: "More",
      height: "60px",
      elipsis: true,
      shouldShowMoreOption: false,
      timeout: "",
    };
  },
  mounted() {
    this.taskName = this.task.name;
    this.prevTaskName = this.taskName;
    EventEmitter.$on("chatBarResize", (val) => {
      this.handleResize();
    });
    window.addEventListener('resize', this.handleResize)
    setTimeout(() => {
      this.identifyLines();
    }, 10);
  },
  methods: {
    ...mapActions([
      "socket_send_note",
      "socket_rename_project",
      "socket_rename_task",
    ]),
    handleResize() {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.identifyLines();
      }, 500);
    },
    adjustHeight() {
      const textarea = this.$refs.taskNameTextarea;

      textarea.style.height = "1px";
      const height = textarea.scrollHeight;
      this.height = height - 7 + "px";
      textarea.style.height = "100%";
      this.moreText = 'Less'
      this.identifyLines();
    },
    identifyLines() {
      const textarea = this.$refs.taskNameTextarea;
      if (textarea) {
        textarea.style.height = "1px";
        const height = textarea.scrollHeight;

        if (this.$route.params.chat_id) {
          if (height <= 40) {
            this.shouldShowMoreOption = false;
          } else {
            this.shouldShowMoreOption = true;
          }

          if (this.elipsis) {
            this.height = "30px";
            document.querySelector(".title-container").classList.remove("pb-15");
            document.querySelector(".title-container").classList.add("pb-0");
          } else {
            document.querySelector(".title-container").classList.remove("pb-0");
            document.querySelector(".title-container").classList.add("pb-15");
            this.height = height - 7 + "px";
          }

          if (height <= 40 || this.elipsis) {
            document.querySelector(".title-container").classList.remove("pb-15");
            document.querySelector(".title-container").classList.add("pb-0");
          }
        } else {
          if (height > 70) {
            
            this.shouldShowMoreOption = true;
            if (this.elipsis) {
              this.height = "60px"
              this.moreText = 'More'
            
            } else this.height = height - 7 + "px";
          } else if (height <= 70 && height > 40) {

            this.shouldShowMoreOption = false;
            if (this.elipsis) {
              this.height = "60px"
              this.moreText = 'More'
            } else this.height = height - 7 + "px";
          
          } else {
          
            this.shouldShowMoreOption = false;
            //   this.height = "30px";
            if (this.elipsis) this.height = "30px";
            else this.height = height - 7 + "px";
          
          }
        }
        textarea.style.height = "100%";
        // setTimeout(() => {
        //   let winHeight = window.innerHeight
        //   document.querySelector('#right_sidebar__bottom').style.height = winHeight - document.querySelector('.right_sidebar__top').offsetHeight + 'px'
        // }, 0)
      }
    },
    toggleExtend() {
      if (!this.shouldShowMoreOption) return
      const textarea = this.$refs.taskNameTextarea;
      textarea.style.height = "1px";
      const height = textarea.scrollHeight;

      if (this.moreText == "More") {
        this.moreText = "Less";
        this.elipsis = false;
        this.height = height - 7 + "px";

        if (this.$route.params.chat_id) {
          document.querySelector(".title-container").classList.remove("pb-0");
          document.querySelector(".title-container").classList.add("pb-15");
        }
      } else if (this.moreText == "Less") {
        this.elipsis = true;
        this.moreText = "More";
        if (this.$route.params.chat_id) {
          this.height = "30px";
          document.querySelector(".title-container").classList.remove("pb-15");
          document.querySelector(".title-container").classList.add("pb-0");
        } else {
          if (height > 40) {
            this.height = "60px";
          } else {
            this.height = "30px";
          }
        }
      }
      // setTimeout(() => {
      //   let winHeight = window.innerHeight;
      //   document.querySelector("#right_sidebar__bottom").style.height =
      //     winHeight -
      //     document.querySelector(".right_sidebar__top").offsetHeight +
      //     "px";
      // }, 0);
      textarea.style.height = height + 'px'
    },
    handleMouseOver(e) {
      // if (selection !== '') {
      //   this.$refs.taskNameTextarea.style.cursor = ''
      //   return
      // }
      this.$refs.highlightingElement.style.zIndex = 3;
      const currentElement = document.elementFromPoint(e.pageX, e.pageY);
      this.$refs.highlightingElement.style.zIndex = 0;
        if (!currentElement.href) {
          this.$refs.taskNameTextarea.style.cursor = "";
        } else {
          this.$refs.taskNameTextarea.style.cursor = "pointer";
          return;
        }
    },
    handleClick(e) {
      // e.preventDefault()
      setTimeout(() => {
        this.moreText = "More";
        this.toggleExtend();
        // const selection = window.getSelection().toString();
        // if (selection !== "") return;
        this.$refs.highlightingElement.style.zIndex = 3;
        const element = document.elementFromPoint(e.pageX, e.pageY);
        if (element.href) {
          element.click()
          this.$refs.taskNameTextarea.blur()
        }
        this.$refs.highlightingElement.style.zIndex = 0;
        this.elipsis = false;
      }, 0);
    },
    urlify(str) {
      // return str
      var urlRegex = /(http(s)?:\/\/[^\s]+)/g;

      str = str.replace(/</g, "&lt;");
      str = str.replace(/>/g, "&gt;");

      str = str.replace(urlRegex, function (url) {
        return (
          '<a href="' + url + '" target="_blank" class="inline">' + url + "</a>"
        );
      });

      // for (let i = 25; i < str.lenght; i += 25) {
      //   str.splice(i, 0, "\n");
      // }

      return str;
    },
    update(text) {
      // let result_element = document.querySelector("#highlightingContentTaskName");
      let result_element = this.$refs.taskNameHighlightingContent;
      // Handle final newlines (see article)
      if (text[text.length - 1] == "\n") {
        text += " ";
      }
      this.adjustHeight();
      // Update code
      result_element.innerHTML = this.urlify(text); /* Global RegExp */
      // Syntax Highlight
    },
    sync_scroll(e) {
      const element = document.getElementById("taskEditing");
      /* Scroll result to scroll coords of event - sync with textarea */
      let result_element = document.querySelector("#taskNameHighlighting");
      // Get and set x and y
      result_element.scrollTop = element.scrollTop;
      result_element.scrollLeft = element.scrollLeft;
    },
    onEdit(e) {
      document.querySelector("textarea.right_sidebar__notes").focus();
      if (!e.path[0].href) {
        this.edit = true;
        setTimeout(() => {
          document.querySelector("textarea.right_sidebar__notes").focus();
        }, 100);
      }
    },
    renameTask(e) {
      e.preventDefault()
      if (this.prevTaskName === this.taskName) return;
      if (this.taskName != "") {
        this.editing = false;
        // document.querySelector('.task_rename').classList.remove('scroll')

        this.taskName = this.taskName.replace(/[\r\n\v]+/g, "");

        if (this.taskName != this.task.name) {
          this.taskName = this.taskName.slice(0, 160);
          // this.adjustHeight();

          if (this.type == "project") {
            this.socket_rename_project({
              name:
                this.taskName.trim() != "" ? this.taskName.trim() : "Untitled",
              _id: this.project._id,
            });
          } else if (this.type == "task" && this.project_id) {
            this.socket_rename_task({
              name:
                this.taskName.trim() != "" ? this.taskName.trim() : "Untitled",
              task_id: this.task._id,
              project_id: this.project_id,
            });

            this.$emit("rename_chat", this.taskName);
          }
        }
      } else {
        this.taskName = this.task.name;
      }

      //   if (window.navigator.onLine) {
      //     this.socket_send_note(req);
      //   }
      this.prevContent = this.noteContent;
      this.$refs.taskNameTextarea.blur()
    },
    checkValue() {
      this.showPlaceholder = !this.noteContent;
    },
    goToNextLine(e) {
      var position = document.querySelector(".right_sidebar__notes")
        .selectionEnd;
      this.noteContent =
        this.noteContent.substring(0, position) +
        "<br/>" +
        this.noteContent.substring(position);

      setTimeout(() => {
        document.querySelector(".right_sidebar__notes").selectionEnd =
          position + 1;
        if (this.noteContent.length == position + 1) {
          document.querySelector(
            ".right_sidebar__notes"
          ).scrollTop = document.querySelector(
            ".right_sidebar__notes"
          ).scrollHeight;
        }
      }, 10);
    },
  },
  computed: {
    ...mapGetters(["getTasks", "get_flag_for_note"]),
  },
  watch: {
    $route() {
      setTimeout(() => {
        this.identifyLines();
        (this.elipsis = false), (this.moreText = "Less");
        this.toggleExtend();
      }, 0);
      //   this.set_note(this.note, this.task_id);
    },
    task(newVal) {
      this.taskName = newVal.name;
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  }
};
</script>

<style lang="scss">

#highlightingContentTaskName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  a {
    color: #489cdc;
  }
}

#taskEditing,
#taskNameHighlighting {
  border: 0;
  width: calc(100% - 40px);
  &::-webkit-scrollbar {
    width: 0;
  }
}

// #taskEditing,
// #taskNameHighlighting {
//   position: absolute;
//   top: 0;
//   left: 0;
// }

#taskEditing {
  z-index: 2;
  outline: none;
}

#taskNameHighlighting {
  background-color: #fff;
  height: 18px;
  z-index: 1;
}

#taskEditing {
  resize: none;
}

.visual-content {
  a {
    display: inline;
  }
}

</style>
