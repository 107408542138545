<template>
    <div 
        class="add_comment_block"
        :id="'add-comment-container-' + type"
        @keydown.down="handleDown" 
        @keydown.up="handleUp" 
        @keydown.left="isTryingToTag" 
        @keydown.right="isTryingToTag"
    >

        <div class="marked_message edit_message" v-if="edit_message">
            <div>
                <font-awesome-icon class="pen-color" :icon="['fas', 'pen']" />
            </div>
            <div>
                <span>Edit</span>
                <p @click="delete_edit_message(edit_message)">
                    <i class="el-icon-close"></i>
                </p>
                <span :ref="'edit_msg_' + edit_message._id" :id="'edit_msg_' + edit_message._id" v-if="edit_message.message">
                    {{ edit_message.message }}
                </span>
                <span class="addcomment_reply" :ref="'edit_msg_' + edit_message._id" :id="'edit_msg_' + edit_message._id" v-else>
                    <div v-if="edit_message.files" class="addcomment_reply__flex">
                        <div v-for="file in edit_message.files" :key="file._id">
                            <img :src="edit_message.file" v-if="file.ext.toLowerCase() == 'png' || file.ext.toLowerCase() == 'jpg' || file.ext.toLowerCase() == 'jpeg'">
                            <div class="addcomment_reply__ext">{{file.ext}}</div>
                        </div>
                    </div>
                </span>
            </div>
        </div>

        <div class="marked_message reply_message" v-else-if="reply_message">
            <div>
                <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.5 5.25V0.25L0.75 9L9.5 17.75V12.625C15.75 12.625 20.125 14.625 23.25 19C22 12.75 18.25 6.5 9.5 5.25Z" fill="#0077FF"/>
                </svg>
            </div>
            <div v-if="reply_message.files && reply_message.files.length > 0">
                <div class="reply_message__img" v-if="reply_message.files[0].ext.toLowerCase() == 'png' || reply_message.files[0].ext.toLowerCase() == 'jpg' || reply_message.files[0].ext.toLowerCase() == 'jpeg'">
                    <img :src="reply_message.file">
                </div>
            </div>
            <div>
                <span>{{ get_user(reply_message.user_id).name }}</span>
                <p @click="delete_reply_message(reply_message)">
                    <i class="el-icon-close"></i>
                </p>
                <span class="reply_message_body" :ref="'reply_message_' + reply_message._id" :id="'reply_message_' + reply_message._id" v-if="reply_message.message" v-html="comment_parse(reply_message.message)">
                    <!-- {{ reply_message.message }} -->
                </span>
                <span class="addcomment_reply" :ref="'reply_message_' + reply_message._id" :id="'reply_message_' + reply_message._id" v-else>
                    <div v-if="reply_message.files" class="addcomment_reply__flex" style="width: 100%">
                        <div v-for="file in reply_message.files" :key="file._id" style="width: 100%">
                           <div class="addcomment_reply__ext">{{file.filename}}</div>
                        </div>
                    </div>
                </span>
            </div>
        </div>

        <div style="position: relative; width: 100%;display: flex; align-items: center;">

            <img :src="require('@/assets/static/images/clipd.svg')" alt="" class="clip_file" @click="$refs.inp_file.click()" :style="edit_message ? 'opacity: 0.6' : ''"/>
            
            <textarea 
                rows="1" 
                ref="add_comment" 
                placeholder="Add a comment..." 
                v-model="comment"
                :id="'add_comment_block_' + type"
                @blur="blurInput"
                @focus="inputFocused = true"
                @input="startTyping"
            ></textarea>
            
            <div @click="comment_send" :disabled="edit_message ? true : false" class="send_comment">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 20V4" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M5 11L12 4L19 11" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>

            <input type="file" name="files" @change="choose_file" ref="inp_file" multiple style="display: none"/>

        </div>
    
        <ul class="comment_files" v-if="list_files.length > 0">
            <li v-for="file in list_files" :key="file._id" class="comment_file" :ref="`file_${file._id}`">
                <span class="comment_file__name">{{ file.filename }}</span>
                <span class="comment_file__delete" @click="delete_file_from_upload_list(file._id)">
                    <i class="el-icon-close"></i>
                </span>
            </li>
        </ul>

        <span class="user_typing">{{ users_typing }}</span>

        <div v-if="sortedUsers.length" class="user-tag">
            <button 
                v-for="(user, idx) in sortedUsers" 
                class="user-tag__user" 
                :id="'suggestion_' + idx" 
                :key="user._id" 
                :class="{active: idx == currentUserIdx}" 
                :tabindex="idx"
                @click="handleChooseUser" 
                @mousemove="() => handleMouseOver(idx)" 
            >
                <img v-if="user.image || (user.user_data && user.user_data.image)" :src="$w_get_image_url(user.user_data ? user.user_data : user)" :alt="user.name" class="user-tag__img" id="high">
                <div v-else class="user-tag__img" id="high" :style="{background: user.color ? user.color : user.user_data.color }">
                    <p class="letter">{{user.name ? user.name.trim()[0]: user.user_data.name.trim()[0] }}</p>
                </div>
                <p class="user-tag__name" id="high">{{ type == 'chat' ? user.user_data.name : user.name }}</p>
                <p class="user-tag__email" id="high">{{ type == 'chat' ? user.user_data.email : user.email }}</p>
                <!-- {{user.email}} -->
            </button>
        </div>

    </div>
</template>

<script>

    import { mapGetters, mapActions, mapMutations } from "vuex"
    import FileAWS from '../../utils/aws'
    import {EventEmitter} from '../../main'

    export default {
        name: "AddComment",
        props: {
            type: {
                type: String
            },
            id: {
                type: String
            }
        },
        data() {
            return {
                comment: "",
                reply_message: "",
                edit_message: "",
                count_message: 0,
                users_typing: "",
                files: {},
                local_files: {},
                msg_id: "",
                precent_loader: {},
                all_file: [],
                send_bool: false,
                load_files: [],
                file_abort: [],
                type_bool: false,
                list_files: [],
                isTyping: false,
                sortedUsers: [],
                currentUserIdx: 0,
                arrowControl: false,
                inputFocused: false,
                tagStart: -1,
                tagEnd: -1,
                updateComment: false
            }
        },
        computed: {
            ...mapGetters([
                "getUserData",
                "getComments",
                "getTasks",
                "get_local_reply_message",
                "get_flag_for_reply_message",
                "get_local_edit_message",
                "get_flag_for_edit_message",
                "get_users_typing",
                "get_flag_for_typing_users",
                "getUserUsedFileSize",
                "getUserAllFileSize",
                "getRender",
                "getKnownUsers",
                "getUsersByProject",
                "getUsersByChat",
                "getChats"
            ]),
            usersByEmail() {
                let allUsers = this.getUsersByChat[this.$route.params.chat_id]
                if (!allUsers) {
                    allUsers = this.getUsersByProject[this.$route.params.project_id] 
                }
                let users = {}
                for (let i = 0; i < allUsers.length; i++) {
                    const name = allUsers[i].name ? allUsers[i].name : allUsers[i].user_data.name
                    const email = allUsers[i].user_data ? allUsers[i].user_data.email : allUsers[i].email
                    users['@' + email] = name
                }
                return users
            }
        },
        mounted() {

            EventEmitter.$on('typing_render', () => {
                if (this.type == 'list') {
                    this.writeTypingUsers(this.get_users_typing, this.$route.params.task_id)
                } else {
                    this.writeTypingUsers(this.get_users_typing, this.$route.params.chat_id)
                }
            })

            if (this.type == 'chat') {

                let chatReplyMessage = JSON.parse(sessionStorage.getItem('chatReplyMessage'))
                let chatEditMessage = JSON.parse(sessionStorage.getItem('chatEditMessage'))

                
                if (chatReplyMessage && chatReplyMessage.chat_id == this.$route.params.chat_id && chatReplyMessage.time > new Date().getTime() && chatReplyMessage.value) {
                    this.reply_message = chatReplyMessage.value
                }

                if (chatEditMessage && chatEditMessage.chat_id == this.$route.params.chat_id && chatEditMessage.time > new Date().getTime() && chatEditMessage.value) {
                    this.edit_message = chatEditMessage.value
                }
                
                let valuesBeforeRouteChange = JSON.parse(sessionStorage.getItem('inputValuesOnRouteChange'))
                
                // console.log(this.type, 'add comments mounted???', valuesBeforeRouteChange)
                if (valuesBeforeRouteChange && valuesBeforeRouteChange.chat_id == this.id) {
                    this.comment = valuesBeforeRouteChange.chatInputValue
                }

                setTimeout(() => {
                    sessionStorage.removeItem('chatReplyMessage')
                    sessionStorage.removeItem('chatEditMessage')
                    sessionStorage.removeItem('inputValuesOnRouteChange')
                }, 3)
            } 


        },
        watch: {
            inputFocused(e) {
                if (e) {
                    // document.querySelector('.right_sidebar').style.height = '100vh'
                } else {
                    // document.querySelector('.right_sidebar').style = ''
                }
            },
            $route() {

                // this.list_files = []
                // this.local_files = []
                // this.files = []

                // this.comment = ''
                this.textarea_resize()

                setTimeout(() => {
                    this.$refs.add_comment.focus()
                    document.querySelector('.comments_block').style.paddingBottom = document.querySelector('.add_comment_block').clientHeight + 'px'
                }, 10)
            },
            async get_flag_for_reply_message() {
                const key = this.type == 'chat' ? 'chat_id' : 'task_id'
                let reply_message = this.get_local_reply_message[this.$route.params[key]]
                if (reply_message) {
                    if (reply_message.files.length > 0) {
                        reply_message.file = await this.get_file_url(reply_message.files[0])
                    }
                    this.reply_message = reply_message
                } else this.reply_message = ''
                setTimeout(() => {
                    document.querySelector('.comments_block').style.paddingBottom = document.querySelector('.add_comment_block').clientHeight + 'px'
                }, 10)
            },
            async get_flag_for_edit_message() {
                let key = this.type == 'chat' ? this.$route.params.chat_id : this.$route.params.task_id

                let edit_message = this.get_local_edit_message[key]

                if (edit_message) {
                    if (edit_message.files.length > 0) {
                        edit_message.file = await this.get_file_url(edit_message.files[0])
                    }
                    this.edit_message = edit_message
                } else this.edit_message = ''

                if (this.get_local_edit_message[key] && this.get_local_edit_message[key] != "") {
                    this.comment = this.get_local_edit_message[key].message
                } else {
                    this.comment = ""
                }

                setTimeout(() => {
                    this.textarea_resize()
                }, 0)

                setTimeout(() => {
                    document.querySelector('.comments_block').style.paddingBottom = document.querySelector('.add_comment_block').clientHeight + 'px'
                }, 10)

            },
            get_flag_for_typing_users() {
                if (this.type == 'list') this.writeTypingUsers(this.get_users_typing, this.$route.params.task_id)
            },
            getRender() {
                if (this.type == 'chat') this.writeTypingUsers(this.get_users_typing, this.$route.params.chat_id)
            },
            comment(e) {
                if (e.length > 3000) {
                    this.comment = e.slice(0, 3000);
                }
                setTimeout(() => {
                    document.querySelector('.comments_block').style.paddingBottom = document.querySelector('.add_comment_block').clientHeight + 'px'
                }, 10)
            }
        },
        methods: {
            ...mapMutations([
                "delete_local_reply_message",
                "change_flag_for_reply_message",
                "delete_local_edit_message",
                "change_flag_for_edit_message",
                "setMessage",
                "setFiles",
                "setUserUsedFileSize",
                "changeCommentLoading",
                "setUserFileSize_minus"
            ]),
            ...mapActions([
                "socket_add_comment",
                "socket_edit_message",
                "socket_start_typing",
                "socket_end_typing",
                "fetch_request",
                "socket_delete_message"
            ]),
            handleChooseUser() {
                const user = this.sortedUsers[this.currentUserIdx]
                if (!user) return
                let email = ''
                if (user.email) {
                    email = user.email.split('')
                } else {
                    email = user.user_data.email.split('')
                }
                let temp = this.comment.split('')
                // console.log(temp, 'before')
                // console.log(this.tagStart, this.tagEnd, tagEnd, 'tags', temp[this.tagEnd + 1])
                temp.splice(this.tagStart + 1, this.tagEnd + 1 - this.tagStart, ...email, ' ')
                temp = temp.join('')
                this.comment = temp
                this.sortedUsers = []
                this.$refs.add_comment.focus()
            },
            isTryingToTag() {
                setTimeout(() => {

                    this.tagStart = 0
                    this.currentUserIdx = 0
                    const element = this.$refs.add_comment
                    
                    const currentPosition = element.selectionStart - 1;
                    
                    this.tagEnd = currentPosition
                    var keyword = ""
                    let word = []
                    // let comment = this.$refs.add_comment.value.replace(/\n\r?/g, ' ')
                    
                    let comment = this.$refs.add_comment.value.replace(/\n\r?/g, ' ')
                    
                    for (let i = currentPosition; i >= 0; i--) {
                        const curr = comment[i]
                        if (curr == ' ' || !curr || curr == '\n') {
                            this.tagStart = i + 1
                            i = -1
                        } else {
                            word.push(curr)
                        }
                    }
                    
                    word = word.reverse().join('')
                    
                    if (word.startsWith('@')) {
                        keyword = word.substring(1).toLowerCase()
                    } else {
                        this.sortedUsers = []
                        return
                    }
                    
                    let users = []
                    // console.log('keyword: ', keyword, this.type)
                    if (this.type == 'list') {
                        users = this.getUsersByProject[this.$route.params.project_id]
                        if (keyword != '') {
                            this.sortedUsers = users.filter(user => user.name.toLowerCase().startsWith(keyword) || user.email.toLowerCase().startsWith(keyword))
                        } else {
                            this.sortedUsers = users
                        }
                    } else if (this.type == 'chat') {
                        users = this.getUsersByChat[this.$route.params.chat_id]
                        
                        if (keyword != '') {
                            this.sortedUsers = users.filter(user => user.user_data.name.toLowerCase().startsWith(keyword) || user.user_data.email.toLowerCase().startsWith(keyword))
                        } else {
                            this.sortedUsers = users
                        }
                    }
                }, 10)
            },
            blurInput(e) {
                this.sendEndTyping(e)
                setTimeout(() => {
                    this.inputFocused = false
                }, 200)
            },
            handleMouseOver(idx) {
                if (!this.arrowControl) {
                    this.currentUserIdx = idx 
                }
            },
            handleUp(e) {
                if (this.sortedUsers.length) {
                    e.preventDefault()
                }
                this.arrowControl = true
                if (this.currentUserIdx > 0) {
                    this.currentUserIdx--
                } else {
                    this.currentUserIdx = this.sortedUsers.length - 1
                }

                if (this.sortedUsers.length) {
                    const currentElement = document.getElementById('suggestion_' + this.currentUserIdx)  
                    this.arrowControl = true
                    currentElement.scrollIntoView({block: 'nearest'})
                }

                setTimeout(() => {
                    this.arrowControl = false
                }, 0)
                // console.log('handleUp', this.currentUserIdx)
            },
            handleDown(e) {
                if (this.sortedUsers.length) {
                    e.preventDefault()
                }

                if (this.currentUserIdx < this.sortedUsers.length - 1) {
                    this.currentUserIdx++
                } else {
                    this.currentUserIdx = 0
                }

                if (this.sortedUsers.length) {
                    const currentElement = document.getElementById('suggestion_' + this.currentUserIdx)  
                    this.arrowControl = true
                    currentElement.scrollIntoView({block: 'nearest'})
                }

                setTimeout(() => {
                    this.arrowControl = false
                }, 0)
            },
            comment_parse(message) {
                let mentions = {}

                let messageArr = message.replace(/\n\r?/g, ' ').split(' ')

                for (let i = 0; i < messageArr.length; i++) {
                    if (messageArr[i] in this.usersByEmail) {
                        mentions[messageArr[i]] = this.usersByEmail[messageArr[i]]
                    }
                }

                let urlRegex = /(http(s)?:\/\/[^\s]+)/g

                message = message.replace(/</g, '&lt;')
                message = message.replace(/>/g, '&gt;')
                
                for (let email in mentions) {
                    message = message.replaceAll(email, `<span class='tagged-user'>${mentions[email]}</span>`)
                }

                return message.replace(urlRegex, function(url) {
                    return '<a href="' + url + '">' + url + '</a>'
                })

            },
            comment_send() {

                if (this.sortedUsers.length) {
                    this.handleChooseUser()
                    return
                }

                if (this.comment.trim().length > 3000) {
                    this.setMessage({
                        key_message: "file length",
                        message: "The maximum number of characters is 3000"
                    })
                }

                // if (this.reply_message && this.local_files.length > 0) {
                if (false) {
                    this.list_files = []
                    this.uploadFileAWS()
                } else {

                    if (this.edit_message) {

                        if (this.comment == '') {

                            let delete_message_popup

                            if (document.querySelector(".custom_message_popup"))
                                delete_message_popup = document.querySelector(".custom_message_popup")
                            else {
                                delete_message_popup = document.createElement("div")
                                delete_message_popup.classList.add("custom_message_popup")
                                this.$root.$el.insertAdjacentElement("beforeend", delete_message_popup)
                            }

                            delete_message_popup.innerHTML = ""
                            const body = document.createElement("div")
                            delete_message_popup.insertAdjacentElement("beforeend", body)
                            const title = document.createElement("div")
                            title.classList.add('text')
                            title.innerHTML = "Do you want to delete this comment?"
                            body.insertAdjacentElement("beforeend", title)
                            const call_to_action_footer = document.createElement("div")
                            call_to_action_footer.classList.add("call_to_action_footer")
                            body.insertAdjacentElement("beforeend", call_to_action_footer)
                            const cancel = document.createElement("button")
                            cancel.innerHTML = "Cancel"
                            call_to_action_footer.insertAdjacentElement("beforeend", cancel)
                            const confirm = document.createElement("button")
                            confirm.classList.add('red')
                            confirm.innerHTML = "Delete"
                            call_to_action_footer.insertAdjacentElement("beforeend", confirm)

                            delete_message_popup.classList.add("active")

                            cancel.addEventListener("click", (e) => {
                                e.preventDefault()
                                delete_message_popup.classList.remove("active")
                            })

                            confirm.addEventListener("click", (e) => {
                                e.preventDefault()

                                const aws = new FileAWS()

                                let comment = this.edit_message

                                if (this.type == 'list') {

                                    if (comment.files) {
                                        comment.files.forEach((e) => {

                                            this.setUserFileSize_minus(+e.size)

                                            if (e.ext.toLowerCase() == 'png' || e.ext.toLowerCase() == 'jpg' || e.ext.toLowerCase() == 'jpeg') {

                                                if (e.imgWidth > 200) {
                                                    let sizes = [200, 'full']
                                                    sizes.forEach((size) => {
                                                        const remove = aws.remove('Lists' + '/' + this.$route.params.project_id + '/' + this.$route.params.task_id + '/' + size + '__' + e._id + '__' + e.filename)
                                                    }) 
                                                } else {
                                                    const remove = aws.remove('Lists' + '/' + this.$route.params.project_id + '/' + this.$route.params.task_id + '/' + e._id + '__' + e.filename)
                                                }

                                            } else {
                                                const remove = aws.remove('Lists' + '/' + this.$route.params.project_id + '/' + this.$route.params.task_id + '/' + e._id + '__' + e.filename)
                                            }

                                        })
                                    }

                                    this.socket_delete_message({
                                        task_id: comment.task_id,
                                        chat_id: comment._id,
                                        user_id: this.getUserData._id,
                                        project_id: this.$route.params.project_id,
                                        comment
                                    })

                                } else {
                                    
                                    if (comment.files) {
                                        comment.files.forEach((e) => {
                                            this.setUserFileSize_minus(+e.size);
                                            if (e.ext.toLowerCase() == 'png' || e.ext.toLowerCase() == 'jpg' || e.ext.toLowerCase() == 'jpeg') {

                                                if (e.imgWidth > 200) {
                                                    let sizes = [200, 'full']
                                                    sizes.forEach((size) => {
                                                        const remove = aws.remove('Chats' + '/' + this.$route.params.chat_id + '/' + size + '__' + e._id + '__' + e.filename)
                                                    }) 
                                                } else {
                                                    const remove = aws.remove('Chats' + '/' + this.$route.params.chat_id + '/' + e._id + '__' + e.filename)
                                                }

                                            } else {
                                                const remove = aws.remove('Chats' + '/' + this.$route.params.chat_id + '/' + e._id + '__' + e.filename)
                                            }
                                        })
                                    }

                                    this.$socket.emit('IN_RemoveMessages', this.$route.params.chat_id, comment._id)

                                }

                                this.delete_edit_message(comment)

                                delete_message_popup.classList.remove("active")
                            })

                            return
                        }

                        let req = {
                            message: this.comment,
                            chat_id: this.edit_message._id
                        }

                        if (this.type == 'chat') {
                            req.chats_id = this.$route.params.chat_id
                        } else if (this.type == 'list') {
                            req.task_id = this.$route.params.task_id
                            req.project_id = this.$route.params.project_id
                        }

                        this.socket_edit_message(req)

                        this.delete_local_edit_message(this.edit_message)
                        this.change_flag_for_edit_message()

                        this.sendEndTyping()
                        this.textarea_resize()

                        this.comment = ''

                        return

                    }

                    if (this.comment.trim()) {

                        let data = {
                            _id: this.$w_decrypt(this.getUserData._id),
                            date: new Date(),
                            message: this.comment.trim(),
                            user_name: this.getUserData.name,
                            user_id: this.getUserData._id,
                            files: [],
                            reply: false,
                            send: false,
                            read: false,
                            error: false
                        }

                        if (this.type == 'chat') {
                            data.chat_id = this.$route.params.chat_id
                        } else if (this.type == 'list') {
                            data.task_id = this.$route.params.task_id
                            data.project_id = this.$route.params.project_id
                        }

                        if (this.reply_message) {
                            data.reply = {
                                message: this.reply_message.message,
                                name: this.reply_message.user_name,
                                _id: this.reply_message._id,
                                user_id: this.reply_message.user_id,
                                files: this.reply_message.files
                            }
                        }

                        this.socket_add_comment({
                            data: data,
                            send: false,
                            type: this.type
                        })

                        this.socket_add_comment({
                            data: data,
                            send: true,
                            type: this.type
                        })

                        setTimeout(() => {
                            this.scroolCommentsBlock()
                        }, 100)

                    }

                    if (this.local_files.length > 0) {

                        this.list_files = []
                        this.uploadFileAWS()

                        setTimeout(() => {
                            this.scroolCommentsBlock()
                        }, 600)

                    }

                }

                this.comment = ''

                setTimeout(() => {
                    if (this.reply_message) {
                        this.delete_local_reply_message(this.reply_message)
                        this.change_flag_for_reply_message()
                    }

                    this.sendEndTyping()
                    this.textarea_resize()
                    document.querySelector('.comments_block').style.paddingBottom = '57px'

                }, 100)

            },
            scroolCommentsBlock() {
                const id = this.type == 'chat' ? 'comments_block_chat' : 'comments_block_list'
                const comments_block = document.getElementById(id)
                comments_block.scrollTop = comments_block.scrollHeight
            },
            uploadFileAWS() {
                if (this.local_files.length > 0) {

                    // if (this.reply_message) {
                    if (this.reply_message && !this.comment) {

                        let files_reply = []

                        this.local_files.forEach( async (e) => {

                            files_reply.push({
                                _id: e._id,
                                size: e.size,
                                filename: e.filename,
                                imgHeight: e.imgHeight,
                                imgWidth: e.imgWidth,
                                ext: this.$w_parse_file_type(e.filename),
                                base64: e.base64
                            })

                        })

                        let data = {
                            _id: this.$w_decrypt(this.getUserData._id),
                            date: new Date(),
                            message: this.reply_message ? this.comment.trim() : '',
                            user_name: this.getUserData.name,
                            user_id: this.getUserData._id,
                            task_id: this.$route.params.task_id,
                            project_id: this.$route.params.project_id,
                            chat_id: this.$route.params.chat_id,
                            files: files_reply,
                            reply: false,
                            send: false,
                            error: false
                        }

                        if (this.reply_message) {
                            data.reply = {
                                message: this.reply_message.message,
                                name: this.reply_message.user_name,
                                _id: this.reply_message._id,
                                user_id: this.reply_message.user_id,
                                files: this.reply_message.files
                            }
                        }

                        this.socket_add_comment({
                            data: data,
                            send: false,
                            type: this.type
                        })

                        this.local_files.forEach( async (e, file_done_num) => {

                            let load_files = this.load_files.filter(a => a == e._id)

                            if (load_files.length == 0) {
                                this.load_files.push(e._id)

                                if (e.ext.toLowerCase() == 'png' || e.ext.toLowerCase() == 'jpg' || e.ext.toLowerCase() == 'jpeg') {

                                    if (e.imgWidth > 200 && e.size > 10000) {

                                        let sizes = [200, 'full']
                                        
                                        for await (let size of sizes) {
                                            await this.$w_resizeImg(size, e.file, async (base64) => {

                                                const file = new FileAWS(base64)

                                                let uploadRequest = ''
                                                if (this.type == 'list') {
                                                    uploadRequest = await file.write('Lists' + '/' + this.$route.params.project_id + '/' + this.$route.params.task_id + '/' + size + '__' + e._id + '__' + e.file.name)
                                                } else {
                                                    uploadRequest = await file.write('Chats' + '/' + this.$route.params.chat_id + '/' + size + '__' + e._id + '__' + e.file.name)
                                                }

                                                await uploadRequest.on('httpUploadProgress', (event) => {
                                                    if (size == 'full') {
                                                        let progressPercentage = Math.round((event.loaded / event.total) * 100)
                                                        this.add_file_loader(e._id, progressPercentage - 1, event.loaded)
                                                        if (this.file_abort.length > 0) {
                                                            let isId = this.file_abort.filter(a => a == data._id)
                                                            if (isId.length > 0) {
                                                                uploadRequest.abort()
                                                            }
                                                        }
                                                    }
                                                })

                                                await uploadRequest.send((err) => {
                                                    if (err) {
                                                        let error_req = {}
                                                        error_req[e._id] = true
                                                        this.$root.$emit("error_req_attachment", error_req)
                                                        console.log("FAILURE!!")
                                                    } else {

                                                        this.clear_file_loader(e._id)

                                                        this.all_file.push({
                                                            _id: e._id,
                                                            size: e.size,
                                                            filename: e.filename,
                                                            imgHeight: e.imgHeight,
                                                            imgWidth: e.imgWidth,
                                                            ext: this.$w_parse_file_type(e.filename)
                                                        })

                                                        if (size == 'full') {

                                                            this.setUserUsedFileSize(+e.size)

                                                            if (file_done_num == this.local_files.length - 1) {
                                                                this.socket_add_comment({
                                                                    data: data,
                                                                    send: true,
                                                                    type: this.type
                                                                })

                                                                this.local_files = []
                                                                this.all_file = []
                                                                this.files = {}

                                                            }

                                                            this.load_files.forEach((e, i) => {
                                                                if (e == e._id) {
                                                                    this.load_files.splice(i, 1) 
                                                                }
                                                            })

                                                        }

                                                    }
                                                })

                                            })
                                        }

                                    } else {
                                        
                                        const file = new FileAWS(e.file)

                                        let uploadRequest = ''
                                        if (this.type == 'list') {
                                            uploadRequest = await file.write('Lists' + '/' + this.$route.params.project_id + '/' + this.$route.params.task_id + '/' + e._id + '__' + e.file.name)
                                        } else {
                                            uploadRequest = await file.write('Chats' + '/' + this.$route.params.chat_id + '/' + e._id + '__' + e.file.name)
                                        }

                                        uploadRequest.on('httpUploadProgress', (event) => {
                                            let progressPercentage = Math.round((event.loaded / event.total) * 100)
                                            this.add_file_loader(e._id, progressPercentage - 1, event.loaded)
                                            if (this.file_abort.length > 0) {
                                                let isId = this.file_abort.filter(a => a == data._id)
                                                if (isId.length > 0) {
                                                    uploadRequest.abort()
                                                }
                                            }
                                        })

                                        uploadRequest.send((err) => {
                                            if (err) {
                                                let error_req = {}
                                                error_req[e._id] = true
                                                this.$root.$emit("error_req_attachment", error_req)
                                                console.log("FAILURE!!")
                                            } else {

                                                this.clear_file_loader(e._id)

                                                this.all_file.push({
                                                    _id: e._id,
                                                    size: e.size,
                                                    filename: e.filename,
                                                    imgHeight: e.imgHeight,
                                                    imgWidth: e.imgWidth,
                                                    ext: this.$w_parse_file_type(e.filename)
                                                })

                                                if (file_done_num == this.local_files.length - 1) {
                                                    this.socket_add_comment({
                                                        data: data,
                                                        send: true,
                                                        type: this.type
                                                    })

                                                    this.local_files = []
                                                    this.all_file = []
                                                    this.files = {}

                                                }

                                                this.load_files.forEach((e, i) => {
                                                    if (e == e._id) {
                                                        this.load_files.splice(i, 1) 
                                                    }
                                                })

                                                this.setUserUsedFileSize(+e.size)

                                            }
                                        })
                                    }

                                } else {

                                    const file = new FileAWS(e.file)

                                    let uploadRequest = ''
                                    if (this.type == 'list') {
                                        uploadRequest = await file.write('Lists' + '/' + this.$route.params.project_id + '/' + this.$route.params.task_id + '/' + e._id + '__' + e.file.name)
                                    } else {
                                        uploadRequest = await file.write('Chats' + '/' + this.$route.params.chat_id + '/' + e._id + '__' + e.file.name)
                                    }

                                    uploadRequest.on('httpUploadProgress', (event) => {
                                        let progressPercentage = Math.round((event.loaded / event.total) * 100)
                                        this.add_file_loader(e._id, progressPercentage - 1, event.loaded)
                                        if (this.file_abort.length > 0) {
                                            let isId = this.file_abort.filter(a => a == data._id)
                                            if (isId.length > 0) {
                                                uploadRequest.abort()
                                            }
                                        }
                                    })

                                    uploadRequest.send((err) => {
                                        if (err) {
                                            let error_req = {}
                                            error_req[e._id] = true
                                            this.$root.$emit("error_req_attachment", error_req)
                                            console.log("FAILURE!!")
                                        } else {

                                            this.clear_file_loader(e._id)

                                            this.all_file.push({
                                                _id: e._id,
                                                size: e.size,
                                                filename: e.filename,
                                                imgHeight: e.imgHeight,
                                                imgWidth: e.imgWidth,
                                                ext: this.$w_parse_file_type(e.filename)
                                            })

                                            if (file_done_num == this.local_files.length - 1) {
                                                this.socket_add_comment({
                                                    data: data,
                                                    send: true,
                                                    type: this.type
                                                })

                                                this.local_files = []
                                                this.all_file = []
                                                this.files = {}

                                            }

                                            this.load_files.forEach((e, i) => {
                                                if (e == e._id) {
                                                    this.load_files.splice(i, 1) 
                                                }
                                            })
                                            
                                            this.setUserUsedFileSize(+e.size)
                                            
                                        }
                                    })

                                }

                            }

                        })

                    } else {

                        this.local_files.forEach( async (e) => {

                            setTimeout(async () => {
                                    
                                let load_files = this.load_files.filter(a => a == e._id)

                                if (load_files.length == 0) {
                                    this.load_files.push(e._id)

                                    let data = {
                                        _id: this.$w_decrypt(this.getUserData._id),
                                        date: new Date(),
                                        message: this.reply_message ? this.comment.trim() : '',
                                        user_name: this.getUserData.name,
                                        user_id: this.getUserData._id,
                                        task_id: this.$route.params.task_id,
                                        project_id: this.$route.params.project_id,
                                        chat_id: this.$route.params.chat_id,
                                        files: [{
                                            _id: e._id,
                                            size: e.size,
                                            filename: e.filename,
                                            imgHeight: e.imgHeight,
                                            imgWidth: e.imgWidth,
                                            ext: this.$w_parse_file_type(e.filename),
                                            base64: e.base64
                                        }],
                                        reply: false,
                                        send: false,
                                        error: false
                                    }

                                    if (this.reply_message) {
                                        data.reply = {
                                            message: this.reply_message.message,
                                            name: this.reply_message.user_name,
                                            _id: this.reply_message._id,
                                            user_id: this.reply_message.user_id,
                                            files: this.reply_message.files
                                        }
                                    }

                                    this.socket_add_comment({
                                        data: data,
                                        send: false,
                                        type: this.type
                                    })

                                    if (e.ext.toLowerCase() == 'png' || e.ext.toLowerCase() == 'jpg' || e.ext.toLowerCase() == 'jpeg') {

                                        if (e.imgWidth > 200 && e.size > 10000) {

                                            let sizes = [200, 'full']
                                            
                                            for await (let size of sizes) {
                                                await this.$w_resizeImg(size, e.file, async (base64) => {

                                                    const file = new FileAWS(base64)

                                                    let uploadRequest = ''
                                                    if (this.type == 'list') {
                                                        uploadRequest = await file.write('Lists' + '/' + this.$route.params.project_id + '/' + this.$route.params.task_id + '/' + size + '__' + e._id + '__' + e.file.name)
                                                    } else {
                                                        uploadRequest = await file.write('Chats' + '/' + this.$route.params.chat_id + '/' + size + '__' + e._id + '__' + e.file.name)
                                                    }

                                                    await uploadRequest.on('httpUploadProgress', (event) => {
                                                        let progressPercentage = Math.round((event.loaded / event.total) * 100)
                                                        this.add_file_loader(e._id, progressPercentage - 1, event.loaded)
                                                        if (this.file_abort.length > 0) {
                                                            let isId = this.file_abort.filter(a => a == data._id)
                                                            if (isId.length > 0) {
                                                                uploadRequest.abort()
                                                            }
                                                        }
                                                    })

                                                    await uploadRequest.send((err) => {
                                                        if (err) {
                                                            let error_req = {}
                                                            error_req[e._id] = true
                                                            this.$root.$emit("error_req_attachment", error_req)
                                                            console.log("FAILURE!!")
                                                        } else {

                                                            this.clear_file_loader(e._id)

                                                            this.all_file.push({
                                                                _id: e._id,
                                                                size: e.size,
                                                                filename: e.filename,
                                                                imgHeight: e.imgHeight,
                                                                imgWidth: e.imgWidth,
                                                                ext: this.$w_parse_file_type(e.filename)
                                                            })

                                                            if (size == 'full') {

                                                                this.setUserUsedFileSize(+e.size)

                                                                this.socket_add_comment({
                                                                    data: data,
                                                                    send: true,
                                                                    type: this.type
                                                                })

                                                                this.load_files.forEach((e, i) => {
                                                                    if (e == e._id) {
                                                                    this.load_files.splice(i, 1) 
                                                                    }
                                                                })

                                                            }

                                                        }
                                                    })

                                                })
                                            }

                                        } else {
                                            const file = new FileAWS(e.file)

                                            let uploadRequest = ''
                                            if (this.type == 'list') {
                                                uploadRequest = await file.write('Lists' + '/' + this.$route.params.project_id + '/' + this.$route.params.task_id + '/' + e._id + '__' + e.file.name)
                                            } else {
                                                uploadRequest = await file.write('Chats' + '/' + this.$route.params.chat_id + '/' + e._id + '__' + e.file.name)
                                            }

                                            uploadRequest.on('httpUploadProgress', (event) => {
                                                let progressPercentage = Math.round((event.loaded / event.total) * 100)
                                                this.add_file_loader(e._id, progressPercentage - 1, event.loaded)
                                                if (this.file_abort.length > 0) {
                                                    let isId = this.file_abort.filter(a => a == data._id)
                                                    if (isId.length > 0) {
                                                        uploadRequest.abort()
                                                    }
                                                }
                                            })

                                            uploadRequest.send((err) => {
                                                if (err) {
                                                    let error_req = {}
                                                    error_req[e._id] = true
                                                    this.$root.$emit("error_req_attachment", error_req)
                                                    console.log("FAILURE!!")
                                                } else {

                                                    this.clear_file_loader(e._id)

                                                    this.all_file.push({
                                                        _id: e._id,
                                                        size: e.size,
                                                        filename: e.filename,
                                                        imgHeight: e.imgHeight,
                                                        imgWidth: e.imgWidth,
                                                        ext: this.$w_parse_file_type(e.filename)
                                                    })

                                                    this.socket_add_comment({
                                                        data: data,
                                                        send: true,
                                                        type: this.type
                                                    })

                                                    this.load_files.forEach((e, i) => {
                                                        if (e == e._id) {
                                                            this.load_files.splice(i, 1) 
                                                        }
                                                    })

                                                    this.setUserUsedFileSize(+e.size)

                                                }
                                            })
                                        }

                                    } else {

                                        const file = new FileAWS(e.file)

                                        let uploadRequest = ''
                                        if (this.type == 'list') {
                                            uploadRequest = await file.write('Lists' + '/' + this.$route.params.project_id + '/' + this.$route.params.task_id + '/' + e._id + '__' + e.file.name)
                                        } else {
                                            uploadRequest = await file.write('Chats' + '/' + this.$route.params.chat_id + '/' + e._id + '__' + e.file.name)
                                        }

                                        uploadRequest.on('httpUploadProgress', (event) => {
                                            let progressPercentage = Math.round((event.loaded / event.total) * 100)
                                            this.add_file_loader(e._id, progressPercentage - 1, event.loaded)
                                            if (this.file_abort.length > 0) {
                                                let isId = this.file_abort.filter(a => a == data._id)
                                                if (isId.length > 0) {
                                                    uploadRequest.abort()
                                                }
                                            }
                                        })

                                        uploadRequest.send((err) => {
                                            if (err) {
                                                let error_req = {}
                                                error_req[e._id] = true
                                                this.$root.$emit("error_req_attachment", error_req)
                                                console.log("FAILURE!!")
                                            } else {

                                                this.clear_file_loader(e._id)

                                                this.all_file.push({
                                                    _id: e._id,
                                                    size: e.size,
                                                    filename: e.filename,
                                                    imgHeight: e.imgHeight,
                                                    imgWidth: e.imgWidth,
                                                    ext: this.$w_parse_file_type(e.filename)
                                                })

                                                this.socket_add_comment({
                                                    data: data,
                                                    send: true,
                                                    type: this.type
                                                })

                                                this.load_files.forEach((e, i) => {
                                                    if (e == e._id) {
                                                        this.load_files.splice(i, 1) 
                                                    }
                                                })
                                                
                                                this.setUserUsedFileSize(+e.size)
                                                
                                            }
                                        })

                                    }

                                }

                            }, 500)

                        })
                    
                    }

                }
                setTimeout(() => {
                    this.scroolCommentsBlock()
                }, 0)
            },
            add_file_loader(file_id, precent, loaded) {

                this.precent_loader[file_id] = {
                    precent: precent,
                    loaded: loaded,
                    new_file: true
                }

                this.$root.$emit("file_onload", this.precent_loader)

            },
            clear_file_loader(file_id) {
                this.precent_loader[file_id] = {
                    precent: 101
                }
                this.$root.$emit("file_onload", this.precent_loader)
            },
            choose_file(e) {

                if (window.navigator.onLine) {

                    let files_size = 0

                    let arr = Array.from(e.target.files)

                    arr.forEach(file => {
                        files_size = Number(files_size) + Number(file.size)
                    })
                
                    if (files_size > this.getUserAllFileSize - this.getUserUsedFileSize) {
                        this.setMessage({
                            key_message: "file length",
                            message: "Not enough space.",
                            after_text_upgrade: 'to get more space.',
                            plan: true
                        })
                        this.$refs.inp_file.value = null
                        return
                    }

                    this.files = []
                    
                    this.msg_id = this.$w_decrypt(this.getUserData._id)
                    this.files[this.msg_id] = []
                    this.local_files = []
                    let files = Array.from(e.target.files)

                    if (files.length > 5) {
                        this.setMessage({
                            key_message: "file length",
                            message: "Files cannot be more than 5."
                        })
                    } else {

                        this.fReaderUploader(files, this)
                        .then((data) => {

                            let filesSize = 0

                            data.forEach((file) => {
                                filesSize += file.size
                            })

                            if (filesSize > this.getUserAllFileSize - this.getUserUsedFileSize) {
                                this.setMessage({
                                    key_message: "file length",
                                    message: "Not enough space.",
                                    after_text_upgrade: 'to get more space.',
                                    plan: true
                                })
                            } else {
                                this.list_files = data
                                this.local_files = data
                                this.files[this.msg_id] = data
                            }

                            this.$refs.inp_file.value = null
                            this.$refs.add_comment.focus()
                            files = []

                        })
                        .catch((error) => {
                            console.log("fReaderUploader error:", error)
                        })
                    }

                } else {
                    alert('offline')
                }

                setTimeout(() => {
                    document.querySelector('.comments_block').style.paddingBottom = document.querySelector('.add_comment_block').clientHeight + 'px'
                }, 10)

            },
            delete_file_from_upload_list(file_id) {

                this.files[this.msg_id] = this.files[this.msg_id].filter((f) => f._id != file_id)
                this.local_files = this.local_files.filter((f) => f._id != file_id)
                this.list_files = this.list_files.filter((f) => f._id != file_id)

                if (this.local_files.length == 0) {
                    this.msg_id = ""
                    this.$refs.inp_file.value = ""
                }

                setTimeout(() => {
                    document.querySelector('.comments_block').style.paddingBottom = document.querySelector('.add_comment_block').clientHeight + 'px'
                }, 10)

            },
            fReaderUploader(files, vue) {

                const filePromises = []

                files.forEach((file, fileIDX) => {

                    if (fileIDX < 5) {

                        let promise = new Promise((resolve, reject) => {

                            if (vue.$w_parse_file_type(file.name) == 'jpg' || vue.$w_parse_file_type(file.name) == 'jpeg' || vue.$w_parse_file_type(file.name) == 'png' || vue.$w_parse_file_type(file.name) == 'gif') {

                                const reader = new FileReader()
                                const decrypt = vue.$w_decrypt(vue.getUserData._id)

                                reader.onload = (ev) => {

                                    let name = String(file.name)
                                    name = this.removeEmojis(name)

                                    var blob = file.slice(0, file.size)
                                    let newFile = new File([blob], name)

                                    const fileObj = {
                                        date: new Date(),
                                        file: newFile,
                                        _id: fileIDX + decrypt + decrypt[Math.floor(Math.random() * (decrypt.length - 1))],
                                        task_id: vue.task_id,
                                        chat_id: vue.msg_id,
                                        base64: ev.target.result,
                                        ext: vue.$w_parse_file_type(file.name),
                                        filename: name,
                                        size: file.size,
                                        loader: 0
                                    }

                                    const img = new Image()

                                    img.onload = function () {
                                        const aspect = this.height / this.width
                                        const newWidth = 229
                                        const newHeight = newWidth * aspect
                                        fileObj.imgWidth = newWidth
                                        fileObj.imgHeight = newHeight
                                    }

                                    img.src = ev.target.result

                                    resolve(fileObj)

                                }

                                reader.readAsDataURL(file)

                                if (reader.error) {
                                    reject(reader.error)
                                }

                            } else {

                                const decrypt = vue.$w_decrypt(vue.getUserData._id)

                                let name = String(file.name)
                                name = this.removeEmojis(name)

                                var blob = file.slice(0, file.size)
                                let newFile = new File([blob], name)

                                const fileObj = {
                                    date: new Date(),
                                    file: newFile,
                                    _id: fileIDX + decrypt + decrypt[Math.floor(Math.random() * (decrypt.length - 1))],
                                    task_id: vue.task_id,
                                    chat_id: vue.msg_id,
                                    ext: vue.$w_parse_file_type(file.name),
                                    filename: name,
                                    size: file.size,
                                    loader: 0
                                }

                                resolve(fileObj)
                            }

                        })

                        filePromises.push(promise)

                    }
                })

                return Promise.all(filePromises)

            },
            removeEmojis(string) {
                var regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g
                return string.replace(regex, '')
            },
            textarea_new_line() {

                this.sortedUsers = []
                if (!this.comment) return
                const textarea = this.$refs.add_comment
                
                const position = textarea.selectionEnd
                this.comment = this.comment.substring(0, position) + "\n" + this.comment.substring(position)

                setTimeout(() => {
                    textarea.selectionEnd = position + 1
                }, 10)

                this.textarea_resize()

                setTimeout(() => {
                    document.querySelector('.comments_block').style.paddingBottom = document.querySelector('.add_comment_block').clientHeight + 'px'
                }, 10)

            },
            textarea_resize() {

                setTimeout(() => {
                    const textarea = this.$refs.add_comment

                    textarea.style.height = "36px";
                    textarea.style.height = textarea.scrollHeight + "px";

                    // const textarea_height_size = window.getComputedStyle(textarea, null).getPropertyValue("line-height")
                    // const textarea_height_line = parseFloat(textarea_height_size.replace("px", ""))
                    // const textarea_height = textarea.offsetHeight
                    // const textarea_height_scroll = textarea.scrollHeight

                    // if (textarea_height < textarea_height_scroll) {
                    //     textarea.style.height = (textarea_height + textarea_height_line) - 15 + "px"
                    // }

                    // if (textarea.value.trim() == "") {
                    //     textarea.style.height = textarea_height_line + "px"
                    // }
                }, 1)

            },
            get_file_url(file) {

                if (this.type == 'list') {
                    this.getComments[this.$route.params.task_id].forEach(e => {
                        if (e.files[0]) {
                            if (file._id == e.files[0]._id) {
                                file = e.files[0]
                            }
                        }
                    })
                } else {
                    let chat = this.getChats.find(e => e._id == this.$route.params.chat_id)
                    chat.messages.forEach(e => {
                        if (e.files[0]) {
                            if (file._id == e.files[0]._id) {
                                file = e.files[0]
                            }
                        }
                    })
                }

                const aws = new FileAWS()
                if (this.type == 'list') {
                    if (file.imgWidth > 200 && file.size > 10000) {
                        return aws.read('Lists' + '/' + this.$route.params.project_id + '/' + this.$route.params.task_id + '/' + '200' + '__' + file._id + '__' + file.filename)
                    } else {
                        return aws.read('Lists' + '/' + this.$route.params.project_id + '/' + this.$route.params.task_id + '/' + file._id + '__' + file.filename)
                    }
                } else {
                    if (file.imgWidth > 200 && file.size > 10000) {
                        return aws.read('Chats' + '/' + this.$route.params.chat_id + '/' + '200' + '__' + file._id + '__' + file.filename)
                    } else {
                        return aws.read('Chats' + '/' + this.$route.params.chat_id + '/' + file._id + '__' + file.filename)
                    }
                }

            },
            delete_edit_message(comment) {
                this.delete_local_edit_message(comment)
                this.change_flag_for_edit_message()
            },
            delete_reply_message(comment) {
                this.delete_local_reply_message(comment)
                this.change_flag_for_reply_message()
            },
            startTyping(e) {
                this.isTryingToTag()
                this.textarea_resize()
                this.sendStartTyping()
                let textarea = this.$refs.add_comment

                if (this.comment.trim()) {

                    let old_message = this.comment

                    // if (!textarea.classList.contains('typing_on')) {
                    //     textarea.classList.add('typing_on')
                    //     this.sendStartTyping()
                    // }

                    setTimeout(() => {
                        if (old_message.trim() == textarea.value || (old_message.trim() != '' && textarea.value == '')) {
                            this.sendEndTyping()
                            textarea.classList.remove('typing_on')
                        }
                    }, 2000)

                }

            },
            sendStartTyping() {

                if (this.isTyping) return

                this.isTyping = true

                if (this.type == 'list') {
                    this.socket_start_typing({
                        task_id: this.$route.params.task_id,
                        project_id: this.$route.params.project_id
                    })
                } else {
                    this.$socket.emit("IN_ChatStartTyping", { chat_id: this.$route.params.chat_id})
                }

            },
            sendEndTyping() {
                if (!this.isTyping) return

                if (this.type == 'list') {
                    this.socket_end_typing({
                        task_id: this.$route.params.task_id,
                        project_id: this.$route.params.project_id
                    })
                } else {
                    this.$socket.emit("IN_ChatEndTyping", { chat_id: this.$route.params.chat_id})
                }

                this.count_message = 0
                this.isTyping = false

            },
            writeTypingUsers(get_users_typing, local_task_id) {
                if (get_users_typing[local_task_id]) {
                    get_users_typing[local_task_id] = get_users_typing[local_task_id].filter(e => e.users.name != undefined)
                    if (get_users_typing[local_task_id].length == 0) {
                        this.users_typing = "";
                    } else if (get_users_typing[local_task_id].length == 1) {
                        this.users_typing = `${get_users_typing[local_task_id][0].users.name} is typing ...`;
                        // localStorage.setItem()
                    } else if (get_users_typing[local_task_id].length == 2) {
                        this.users_typing = `${get_users_typing[local_task_id][0].users.name} and ${get_users_typing[local_task_id][1].users.name} are typing ...`;
                    } else if (get_users_typing[local_task_id].length > 2) {
                        this.users_typing = `${get_users_typing[local_task_id][0].users.name} and ${get_users_typing[local_task_id][1].users.name} and ${get_users_typing[local_task_id].length - 2} more are typing ...`;
                    }
                } else {
                    this.users_typing = "";
                }
            },
            get_user(user_id) {
                if (this.getUserData._id == user_id) {
                    return this.getUserData
                } else {
                    return this.getKnownUsers.find(user => user._id == user_id)
                }
            }
        },
        beforeDestroy() {
            const time = new Date().getTime() + 1000 * 3
            if (this.type == 'chat' && this.id) {
                sessionStorage.setItem('chatReplyMessage', JSON.stringify({value: this.reply_message, time, chat_id: this.id }))
                sessionStorage.setItem('chatEditMessage', JSON.stringify({value: this.edit_message, time, chat_id: this.id }))
            }
        }
    }

</script>
