<template>
    <div v-if="$route.matched[0].name != 'Inbox'">
        <div class="chats-top__cont-item assign-to">
            <div v-if="assignedUser" class="chats-top__item-img-wrap active">
                <p v-html="$w_user_image(assignedUser, 70, false)"></p>
            </div>
            <div v-else class="chats-top__item-img-wrap">
                <img :src="require(`@/assets/static/images/assign_task.svg`)" alt="">
            </div>
            <span class="right_sidebar__assign__input" :class="assignedUser ? 'active' : ''">
                <el-autocomplete
                    v-model="assignedUserName"
                    :fetch-suggestions="querySearch"
                    placeholder="Assign to"
                    @select="handleSelect"
                    popper-class="assign_users_menu"
                >
                    <template slot-scope="{ item }">
                        <div class="assign_user_item" :class="assignedUser.name == item.name ? 'active' : ''">
                            <span class="smallName" :style="'background-color: ' + item.color" :class="{ unassigned: !item._id }" v-html="item.icon || $w_user_image(item, 70, false)"></span>
                            <span>
                                {{ item.name }}
                                {{ item._id == getUserData._id ? " (me)" : "" }}
                            </span>
                            <img v-show="assignedUser.name == item.name" :src="require(`@/assets/static/images/check_mark.svg`)" alt="">
                        </div>
                    </template>
                </el-autocomplete>
            </span>
            <span v-if="assignedUser" @click="assignUserClear" class="right_sidebar__assign_user_clear">
                <img :src="require(`@/assets/static/images/assign-close.svg`)" alt="">
            </span>
        </div>
    </div>
</template>

<script>

    import { mapGetters, mapActions } from "vuex"
    import { EventEmitter } from '../../main'

    export default {
        data() {
            return {
                assignedUser: '',
                project: null,
                assignedUserBody: null,
                assign_user_value: false,
                project_id: '',
                assignedUserName: ''
            }
        },
        created() {
            this.project = this.getProjects.find((pr) => pr._id == this.$route.params.project_id)
        },
        mounted() {

            this.project_id = this.$route.params.project_id

            this.render()

            // ================================================
            EventEmitter.$on('AssignUser', (data) => {
                if(data.user_id){
                    if (data.task_id == this.$route.params.task_id) {
                        if(this.getUserData._id == data.user_id){
                            this.assignedUser = this.getUserData;
                            this.assignedUserName = this.getUserData.name
                        }else{
                            this.getKnownUsers.forEach((e) => {
                                if(e._id == data.user_id) {
                                    this.assignedUser = e;
                                    this.assignedUserName = e.name
                                }
                            })
                        }
                    }
                    EventEmitter.$emit('OutAssignUserName', this.assignedUser)
                }else{
                    this.assignedUser = "";
                    this.assignedUserName = ''
                }
            })
            
            if (document.getElementsByClassName('right_sidebar__assign')[0]) {
                document.getElementsByClassName('right_sidebar__assign')[0].onclick = () => {
                    if (this.assignedUser) {
                        this.assign_user_value = true
                        this.assignUserClear()
                    }
                }
            
            }

            window.addEventListener("click", () => {
                document.querySelector('.chats-top__cont-item.assign-to').classList.remove('active')
                document.querySelector('.content').classList.remove('filter-blur--assign')
            })

        },
        methods: {
            querySearch(queryString, cb) {
                this.focusSelect()
                // setTimeout(() => {
                    if(this.assign_user_value){
                        queryString = ''
                        this.assign_user_value = false
                    }
                    
                    let users = []

                    this.project.users.forEach(e => {
                        if (this.getUserData._id == e._id) {
                            users.push(this.getUserData)
                        } else {
                            this.getKnownUsers.forEach((user) => {
                                if(e._id == user._id) {
                                    users.push(user)
                                }
                            })
                        }
                    })

                    const results = queryString ? users.filter(this.filter(queryString)) : users

                    if (!results.some((user) => !user._id)) {
                        results.unshift({ name: "Unassigned", _id: null, icon: `
                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.5 25C5.6 25 0 19.4 0 12.5C0 5.6 5.6 0 12.5 0C19.4 0 25 5.6 25 12.5C25 19.4 19.4 25 12.5 25ZM12.5 1.2C6.3 1.2 1.2 6.3 1.2 12.5C1.2 18.7 6.3 23.8 12.5 23.8C18.7 23.8 23.8 18.7 23.8 12.5C23.8 6.3 18.7 1.2 12.5 1.2Z" fill="#474647"/>
                                <path d="M12.4996 12.5C10.5996 12.5 9.09961 11 9.09961 9.1C9.09961 7.2 10.5996 5.7 12.4996 5.7C14.3996 5.7 15.8996 7.2 15.8996 9.1C15.8996 10.9 14.3996 12.5 12.4996 12.5ZM12.4996 6.9C11.2996 6.9 10.2996 7.9 10.2996 9.1C10.2996 10.3 11.2996 11.3 12.4996 11.3C13.6996 11.3 14.6996 10.3 14.6996 9.1C14.6996 7.9 13.6996 6.9 12.4996 6.9Z" fill="#474647"/>
                                <path d="M17.2998 19.3H7.6998C6.8998 19.3 6.2998 18.7 6.2998 17.9V15.9C6.2998 14.3 7.5998 13.1 9.0998 13.1H15.8998C17.4998 13.1 18.6998 14.4 18.6998 15.9V17.9C18.6998 18.7 18.0998 19.3 17.2998 19.3ZM9.0998 14.4C8.1998 14.4 7.4998 15.1 7.4998 16V18C7.4998 18.1 7.5998 18.2 7.6998 18.2H17.2998C17.3998 18.2 17.4998 18.1 17.4998 18V16C17.4998 15.1 16.7998 14.4 15.8998 14.4H9.0998Z" fill="#474647"/>
                            </svg>
                        ` })
                    }

                    cb(results)
                // }, 300)
                
            },
            check_project_id() {
                if (this.$route.params.chat_id) {
                    let task_id = this.$route.params.task_id
                    let parent_position = null
                    let all_ids = Object.keys(this.getTasks)
                    let tasks_group = Object.values(this.getTasks)

                    for (let id in tasks_group) {
                        let tasks = tasks_group[id]
                        if (tasks.length) {
                            for( let i in tasks ) {
                                if (tasks[i]._id == task_id) {
                                    parent_position = id
                                    break
                                }
                            }

                            if (parent_position == id) {
                                break
                            }
                        }
                    }

                    if (parent_position) {
                        this.project_id = all_ids[parent_position]
                    }

                }
            },
            assignUserClear() {

                this.check_project_id()

                this.$socket.emit('IN_AssignUser', {
                    task_id: this.$route.params.task_id,
                    user_id: '',
                    project_id: this.project_id,
                    author: this.getUserData.name
                })
                this.assignedUser = ""
                EventEmitter.$emit('OutAssignUserName', this.assignedUser)
            },
            filter(queryString) {
                return (user) => {
                    return user.name.toLowerCase().includes(queryString.toLowerCase())
                }
            },
            focusSelect() {
                document.querySelector('.chats-top__cont-item.assign-to').classList.add('active')
                document.querySelector('.content').classList.add('filter-blur--assign')
            },
            handleSelect(user) {

                document.querySelector('.chats-top__cont-item.assign-to').classList.remove('active')
                document.querySelector('.content').classList.remove('filter-blur--assign')
                
                this.check_project_id()

                const assignedBody = {
                    task_id: this.$route.params.task_id
                }

                if (!user._id) {
                    assignedBody.user_id = null
                    this.assignedUser = ""
                    this.assignedUserName = ""
                } else {
                    this.assignedUser = user
                    this.assignedUserName = user.name
                    assignedBody.user_id = user._id
                    EventEmitter.$emit('AssignUser', {
                        task_id: this.$route.params.task_id,
                        user_id: user._id,
                        project_id: this.$route.params.project_id
                    })
                    this.$socket.emit('IN_AssignUser', {
                        task_id: this.$route.params.task_id,
                        user_id: user._id,
                        project_id: this.$route.params.project_id,
                        author: this.getUserData.name
                    })
                }
                setTimeout(() => {
                    if (document.querySelector('.avatar_img')) {
                        document.querySelectorAll('.avatar_img').forEach((e) => {
                            e.parentNode.classList.add("avatar_img_span")
                        })
                    }
                }, 10)
            },
            render(task_id) {
                let assign = this.getAssigns.find(e => e.task_id == this.$route.params.task_id)
                if (!assign) return
                if (assign.user_id) {
                    if (this.getUserData._id == assign.user_id) {
                        this.assignedUser = this.getUserData
                        this.assignedUserName = this.getUserData.name
                    } else {
                        this.getKnownUsers.forEach((e) => {
                            if (e._id == assign.user_id) {
                                this.assignedUser = e
                                this.assignedUserName = e.name
                            }
                        })
                    }
                } else {
                    this.assignedUser = ''
                    this.assignedUserName = ''
                }
            }
        },
        computed: {
            ...mapGetters([
                "getProjects", 
                "getTasks", 
                "getUserData", 
                "getKnownUsers", 
                "getAssigns", 
                "getRenderKnownUsers", 
                "getRenderUserData"
            ])
        },
        watch: {
            getRenderKnownUsers() {
                setTimeout(() => {
                    if (this.getUserData._id == this.assignedUser._id) {
                        this.assignedUser = this.getUserData
                        this.assignedUserName = this.getUserData.name
                    } else {
                        this.getKnownUsers.forEach((e) => {
                            if(e._id == this.assignedUser._id) {
                                this.assignedUser = e
                                this.assignedUserName = e.name
                            }
                        })
                    }
                }, 500)
            },
            getRenderUserData() {
                setTimeout(() => {
                    if(this.getUserData._id == this.assignedUser._id) {
                        this.assignedUser = this.getUserData
                        this.assignedUserName = this.getUserData.name
                    }
                }, 1000)
            }
        }
    }

</script>
